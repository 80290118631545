import React, { useEffect } from "react";
import "./Lusia.css";
import "./responsive.css";
import img from "../../Assets-Dental/Line 10.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import arrow from "../../Assets-Dental/arrow.svg";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";

const Lusia = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div className="lusia-conatiner">
      <div className="lusia-container-full-contained">
        <div className="lusia-left">
          <div
            className="lusia-contained"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-duration="600"
          >
            <div className="lusia-content">
              <div className="lusia-img">
                <img src={img} alt="" />
              </div>
              <div className="lusia-heading">
                <div className="lusia-heading-control">Meet Dr.Luisa</div>
              </div>
            </div>
            <div className="lusia-heading-again">
              <h2>Dr Luisa Mateescu</h2>
            </div>
            <div className="lusia-para">
              <p>
                GDC –{" "}
                <a
                  href="https://olr.gdc-uk.org/SearchRegister/SearchResult?RegistrationNumber=276215
                  "
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  276215
                </a>
              </p>
              <p>DMD Romania, 2018</p>
            </div>
            <div className="left-button1">
              <Link to="/contact">
                <button onClick={scrollToTop}>
                  Get In Touch
                  <img src={arrow} alt="" />
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="lusia-right"
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-duration="700"
        >
          <div className="lusia-right-contained">
            <div className="right-para">
              <p>
                Dr Luisa Mateescu qualified in Romania in 2017 at the Faculty of
                Dentistry, University of Medicine and Pharmacy ‘Carol Davila’ in
                Bucharest and started her career alongside oro-maxillo-facial
                surgeons and endodontic specialists within a private
                environment.
              </p>
            </div>

            <div className="right-para bbbbbbb" style={{ color: "white" }}>
              <p>
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum. voluptate velit
                esse cillum dolore eu fugiat nulla pariatur.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lusia;
