import React from "react";
import injection from "../../Assets-Dental/123.png";
import Line from "../../Assets-Dental/Line 10.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import Img1 from "../../Assets-Dental/1-img.png";
import Img2 from "../../Assets-Dental/2-img.png";
import { Link } from "react-router-dom";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";

const EnlightStory = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <div className="expert-content-container-full-responsive">
        <div className="expert-content-container-full">
          <div className="expert-container-story1">
            <div className="expert-container-contained1-story">
              <div
                className="expert-right-story"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="expert-right-content-container1">
                  {" "}
                  {/* <div className="expert-content-container">
                    <div className="expert-content1">
                      <div className="expert-img1">
                        <img src={Line} alt="" />
                        <div className="expert-heading-control1">Our story</div>
                      </div>
                    </div>
                  </div> */}
                  <div className="expert-heading-container-story">
                    <p>Aftercare and Maintenance</p>
                  </div>
                  <div className="expert-para-container-story">
                    <p>
                      To keep your smile shining bright, we'll guide you through
                      the dos and don'ts post-whitening and suggest lifestyle
                      adjustments that can help maintain the results. You can
                      visit us to top-up your whitening gels.
                    </p>
                  </div>
                  <div className="expert-heading-container-story">
                    <p>The Power of a Whiter Smile</p>
                  </div>
                  <div className="expert-para-container-story1">
                    <p>
                      A whiter smile can not only enhances your appearance but
                      also boosts your confidence in both personal and
                      professional interactions. Let us help you unlock the
                      power of your smile.
                    </p>
                    {/* <p>
                  Ready for a dental check-up or need a trusted dentist for
                  your family? Get in touch to book an appointment and
                  experience the difference of dedicated dental care.
                </p> */}
                  </div>
                  <div className="about-button10">
                    <Link to="/contact">
                      <button onClick={scrollToTop}>
                        Get In Touch
                        <img src={arrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="expert-left-story"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="expert-left-contained-story">
                  <img src={injection} alt="" />
                  <div className="story-content-img-flex">
                    <div className="story-mg-flex">
                      <div className="expert-img-container-contained">
                        <img src={Img1} alt="" />
                      </div>
                      <div className="expert-img-container-contained1">
                        <img src={Img2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnlightStory;
