import React from "react";
import "./Dental.css";
import "./responsive.css";
import Img from "../../Assets-Dental/dental.png";

const Dental = () => {
  return (
    <>
      <div className="dental-container">
        <div className="dental-image">
          <img src={Img} alt="" />
        </div>
      </div>
    </>
  );
};

export default Dental;
