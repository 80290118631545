import React from 'react'
import NavbarTreat from '../Navbar-treat/NavbarTreat'
import ContactTreat from '../Contact-treat/ContactTreat'
import Footer from '../Footer/Footer'
import Teeth from '../Teeth/Teeth'
import FATop from '../FacialAestheticTop/FATop'
import FAWhat from '../FacialAestheticWhat/FAWhat'
import FAProcess from '../FacialAestheticProcess/FAProcess'
import FAStory from '../FacialAestheticStory/FAStory'

export default function FacialAMain() {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
   <NavbarTreat/>
   <FATop/>
   <FAWhat/>
   <Teeth/>
   <FAProcess/>
   <FAStory/>
   <ContactTreat/>
    <div style={{ marginTop: "4%" }}></div>
    <Footer/>
</div>
  )
}
