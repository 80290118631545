import React, { useEffect } from "react";
import arrow2 from "../../Assets-Dental/arrow-circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const RootCProcess = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <>
        <div className="process-flex">
          <div className="process-main-dental">
            <div className="process-main-inside">
              <div
                className="process-first-section"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="dental-flex-hhh">
                  <div className="process-first-section-heading-dental-pro">
                    <h3>Dr Luisa's Approach to Your Comfort and Care</h3>
                  </div>
                </div>
                <div className="process-para-contained">
                  <div className="process-first-section-content-pro">
                    <p>
                      Dr Luisa utilises the latest magnification equipment and
                      pain management to ensure your comfort throughout the
                      process. You'll be talked through each step of the
                      treatment before we begin.
                    </p>
                    {/* <p style={{ color: "transparent" }} className="p-remove">
        As an experienced Invisalign® provider, Dr Luisa, utilizes a
        combination of advanced 3D imaging technology and a
        personalized treatment plan to ensure that your journey to a
        straighter smile is smooth and efficient.
      </p> */}
                    <p>
                      If you're experiencing dental discomfort or have been
                      advised to consider root canal treatment, let’s discuss
                      how we can help. Contact us to schedule a consultation.
                    </p>
                  </div>
                </div>
                <div className="process-first-sectiion-button">
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none" }}
                    onClick={scrollToTop}
                  >
                    <button>
                      Book an appointment <img src={arrow2} alt="arrow" />
                    </button>
                  </Link>
                </div>
              </div>

              <div
                className="process-second-section"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="process-second-section-block-container">
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>1. Diagnosis</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          Dr Luisa begins with a thorough examination, including
                          X-rays, to assess the extent of the infection.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>2. Anaesthesia</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          A local anaesthetic is used to numb the area, ensuring
                          you feel no discomfort during the procedure.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>3. Removing the Infection</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          The infected pulp is carefully removed, the root canal
                          is cleaned and shaped, preparing it for a filling.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>4. Filling the Tooth</h4>
                      </div>
                      <div className="process-second-section-block-content1">
                        <p>
                          The cleaned root canal is filled and sealed with a
                          material that prevents bacteria from re-entering.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="process-second-section-block "
                    // style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="process-second-section-block-content-container"
                      //   style={{ backgroundColor: "white" }}
                    >
                      <div className="process-second-section-block-heading">
                        <h4>5. Restoration</h4>
                      </div>
                      <div className="process-second-section-block-content2">
                        <p>
                          A crown or filling is then placed on the tooth to
                          restore its natural shape, appearance, and function.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default RootCProcess;
