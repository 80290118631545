import Navbar from "../Navbar/Navbar";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Invis from "../Invis/Invis";
import Offer from "../Offer/Offer";
import Teeth from "../Teeth/Teeth";
import Story from "../Story/Story";
import Process from "../Process/Process";
import ContactTreat from "../Contact-treat/ContactTreat";
import NavbarTreat from "../Navbar-treat/NavbarTreat";

const TreatmentPage = () => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      {/* <Navbar /> */}
      <NavbarTreat />
      <Invis />
      <Offer />
      <Teeth />
      <Process />
      <Story />
      <ContactTreat />
      <div style={{ marginTop: "4%" }}></div>
      <Footer />
    </div>
  );
};

export default TreatmentPage;
