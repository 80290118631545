import React from 'react'
import NavbarTreat from '../Navbar-treat/NavbarTreat'
import ContactTreat from '../Contact-treat/ContactTreat'
import Footer from '../Footer/Footer'
import InvisalignTop from '../InvisalignTop/InvisalignTop'
import IWhat from '../InvisalignWhat/IWhat'
import Teeth from '../Teeth/Teeth'
import IProcess from '../InvisalignProcess/IProcess'
import IStory from '../InvisalignStory/IStory'

export default function IMmain() {
  return (
    <div>
       <div style={{ width: "100%", overflow: "hidden" }}>
      <NavbarTreat/>
      <InvisalignTop/>
      <IWhat/>
      <Teeth/>
      <IProcess/>
      <IStory/>
      <ContactTreat/>
      <div style={{ marginTop: "4%" }}></div>
      <Footer/>
    </div>
    </div>
  )
}
