import React from "react";
import "./Privacy.css";
import "./responsive.css";
import Line from "../Assets-Dental/Line 10.svg";

const Privacy = () => {
  return (
    <div className="provacy-set-max-flex">
      <div
        className="privacy-container
    "
      >
        <div className="provacy-content-set">
          {" "}
          <div className="expert-content expert-set-fluxxx set-max">
            <div className="expert-img gallery-set">
              <img src={Line} alt="" />
              <div className="expert-heading-control">Dr Luisa Mateescu</div>
              <img src={Line} alt="" style={{ marginLeft: "1%" }} />
            </div>
          </div>
          <div className="gallery-heading expert-set-fluxxx expert-set-fluxxx1">
            <h2>Privacy & Cookie Policy</h2>
          </div>
          <div className="privacy-container-contained">
            <div className="privacy-set-flex">
              <div className="privacy-heading">
                <h1>Website Privacy Notice</h1>
                <h1>Name: Dr Luisa Mateescu</h1>
                <h1>Address: 107 Archer Road, Redditch, B98 8DJ</h1>
                <h1>E-mail: hello@drluisa.co.uk</h1>
              </div>
              <div className="para-set-privacy">
                <p>The type of personal information we collect</p>
              </div>
              <div className="privacy-heading">
                <h1>
                  Through this website, Dr Luisa Mateescu currently collects and
                  process the following information:
                </h1>
                <div className="content-bullet-set">
                  <div className="line-container">
                    <div className="line-li"></div>
                  </div>
                  <div className="para-setmaf-flus">
                    <p>
                      {" "}
                      Personal identifiers and contacts (for example, name and
                      contact details)
                    </p>
                  </div>
                </div>
                <div className="para-set-privacy">
                  <p>How we get the personal information and why we have it</p>
                </div>
                <h1>
                  Most of the personal information Dr Luisa Mateescu processes
                  through this website is provided to us directly by you to :
                </h1>
                <div className="content-bullet-set">
                  <div className="line-container line-setting responsd-para-set">
                    <div className="line-li"></div>
                  </div>
                  <div className="para-setmaf-flus ">
                    <p>
                      {" "}
                      Respond to your enquires and interest into dental
                      treatment provided by Dr Luisa Mateescu.
                    </p>
                  </div>
                </div>
                <h1>
                  Dr Luisa Mateescu may share this information with the
                  practices he works at to book a consultation following your
                  enquiry. You can find out more on how the practice controls
                  your data by visiting their website or asking a member of
                  reception team.
                  <br />
                  Under the General Data Protection Regulation (GDPR) and Data
                  Protection Act 2018 (DPA18), the lawful bases we rely on for
                  processing this information are:
                </h1>
                <div className="content-bullet-set">
                  <div className="line-containealpa line-a-set">
                    <p>(a)</p>
                  </div>
                  <div className="para-setmaf-flus">
                    <p>
                      {" "}
                      Your consent. You are able to remove your consent at any
                      time. You can do this by contacting contact@dr Luisa
                      Mateescu.com
                    </p>
                  </div>
                </div>
                <div className="para-set-privacy">
                  <p>How we store your personal information</p>
                </div>
                <h1>
                  Your information is securely stored in the United Kingdom.
                  <br />
                  We keep the information you provide through the contact form
                  on this page for as long as required by the purpose they have
                  been collected for.
                </h1>
                <div className="para-set-privacy">
                  <p>Your data protection rights</p>
                </div>
                <h1>
                  Under data protection law, you have rights including:
                  <br />
                  Your right of access - You have the right to ask us for copies
                  of your personal information.
                  <br />
                  Your right to rectification - You have the right to ask us to
                  rectify personal information you think is inaccurate. You also
                  have the right to ask us to complete information you think is
                  incomplete.
                  <br />
                  Your right to erasure - You have the right to ask us to erase
                  your personal information in certain circumstances. <br />
                  Your right to restriction of processing - You have the right
                  to ask us to restrict the processing of your personal
                  information in certain circumstances.
                  <br />
                  Your right to object to processing - You have the the right to
                  object to the processing of your personal information in
                  certain circumstances. <br />
                  Your right to data portability - You have the right to ask
                  that we transfer the personal information you gave us to
                  another organisation, or to you, in certain circumstances.{" "}
                  <br />
                  You are not required to pay any charge for exercising your
                  rights. If you make a request, we have one month to respond to
                  you.
                  <br />
                  Please contact us at dataprotection@mydentist.co.uk if you
                  wish to make a request.
                </h1>
                <div className="para-set-privacy">
                  <p>How to complain</p>
                </div>
                <h1>
                  If you have any concerns about our use of your personal
                  information, you can make a complaint to us at
                  dataprotection@mydentist.co.uk.
                  <br />
                  You can also complain to the ICO if you are unhappy with how
                  we have used your data. The ICO’s address: <br />
                  Information Commissioner’s Office
                  <br />
                  Wycliffe House <br />
                  Water Lane
                  <br />
                  Wilmslow <br />
                  Cheshire
                  <br />
                  SK9 5AF
                </h1>
                <h1 style={{ marginTop: "30px" }}>
                  Helpline number: 0303 123 1113
                </h1>
                <h1 style={{ marginBottom: "60px" }}>
                  ICO website:
                  <a
                    href="https://ico.org.uk/"
                    style={{ textDecoration: "none", color: "#2d4e68" }}
                  >
                    https://www.ico.org.uk
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
