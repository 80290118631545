import React from "react";
import "./Teeth.css";
import teeth from "../../Assets-Dental/teeth-img.png";

const Teeth = () => {
  return (
    <div className="teeth-flex">
      <div className="teeth-container-contained">
        <div className="teeth-container">
          <img src={teeth} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Teeth;
