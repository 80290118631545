import React, { useEffect } from "react";
import arrow2 from "../../Assets-Dental/arrow-circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const IProcess = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <>
        <div className="process-flex">
          <div className="process-main-dental">
            <div className="process-main-inside">
              <div
                className="process-first-section"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="dental-flex-hhh">
                  <div className="process-first-section-heading-dental-pro">
                    <h3>Dr Luisa's Approach to Straightening Smiles</h3>
                  </div>
                </div>
                <div className="process-para-contained">
                  <div className="process-first-section-content-pro">
                    <p>
                      As an Invisalign® provider, Dr Luisa, utilises a
                      combination of advanced 3D imaging technology and a
                      personalised treatment plan to ensure that your journey to
                      a straighter smile is smooth and efficient.
                    </p>
                    {/* <p style={{ color: "transparent" }} className="p-remove">
        As an experienced Invisalign® provider, Dr Luisa, utilizes a
        combination of advanced 3D imaging technology and a
        personalized treatment plan to ensure that your journey to a
        straighter smile is smooth and efficient.
      </p> */}
                    <p>
                      Ready to see how Invisalign® can change your smile and
                      your life? Dr Luisa invites you to book a consultation.
                      Get in touch to find out if Invisalign® is right for you.
                    </p>
                  </div>
                </div>
                <div className="process-first-sectiion-button">
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none" }}
                    onClick={scrollToTop}
                  >
                    <button>
                      Book an appointment <img src={arrow2} alt="arrow" />
                    </button>
                  </Link>
                </div>
              </div>

              <div
                className="process-second-section"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="process-second-section-block-container">
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>1. Assessment</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          A detailed examination and digital scans form the
                          basis for your custom aligners.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>2. Custom Aligners</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          Using Invisalign®'s proprietary software, a series of
                          aligners are designed that will move your teeth.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>3. Progress Monitoring</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          With regular check-ups, your progress is monitored as
                          the treatment progresses.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>4. Final Adjustments</h4>
                      </div>
                      <div className="process-second-section-block-content1">
                        <p>
                          Once your teeth have reached their desired positions,
                          a final set of aligners are created to ensure a
                          perfect finish.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="process-second-section-block "
                    // style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="process-second-section-block-content-container"
                      //   style={{ backgroundColor: "white" }}
                    >
                      <div className="process-second-section-block-heading">
                        <h4>5. Retention</h4>
                      </div>
                      <div className="process-second-section-block-content2">
                        <p>
                          You'll get fitted with a retainer to maintain your new
                          smile and prevent future shifting.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default IProcess;
