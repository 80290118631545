import React, { useEffect } from "react";
import arrow2 from "../../Assets-Dental/arrow-circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const BondingProcess = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <>
        <div className="process-flex">
          <div className="process-main-dental">
            <div className="process-main-inside">
              <div
                className="process-first-section"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="dental-flex-hhh">
                  <div className="process-first-section-heading-dental-process">
                    <h3>Dr Luisa's Approach to Smile Enhancement</h3>
                  </div>
                </div>
                <div className="process-para-contained">
                  <div className="process-first-section-content">
                    <p>
                      Dr Luisa combines the latest materials in dentistry with
                      her eye for detail to deliver customised, aesthetic
                      results. She works closely with you to achieve the desired
                      outcome that complements your overall smile.
                    </p>
                    {/* <p style={{ color: "transparent" }} className="p-remove">
            As an experienced Invisalign® provider, Dr Luisa, utilizes a
            combination of advanced 3D imaging technology and a
            personalized treatment plan to ensure that your journey to a
            straighter smile is smooth and efficient.
          </p> */}
                    <p>
                      Ready to transform your smile with composite bonding? Get
                      in touch to book an appointment and take the first step
                      towards a flawless smile.
                    </p>
                  </div>
                </div>
                <div className="process-first-sectiion-button">
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none" }}
                    onClick={scrollToTop}
                  >
                    <button>
                      Book an appointment <img src={arrow2} alt="arrow" />
                    </button>
                  </Link>
                </div>
              </div>

              <div
                className="process-second-section"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="process-second-section-block-container">
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>1. Consultation</h4>
                      </div>
                      <div className="process-second-section-block-content-process">
                        <p>
                          Dr Luisa will begin with a detailed discussion about
                          your smile goals and a thorough dental examination
                          before desiging your new smile.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>2. Preparation</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          The tooth surface is gently prepared to accomadate the
                          bonding material.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>3. Application</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          The composite resin is applied in layers and sculpted
                          to the desired shape.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>4. Curing</h4>
                      </div>
                      <div className="process-second-section-block-content1">
                        <p>
                          Each layer is cured with a high-intensity light to
                          ensure strength and durability.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="process-second-section-block "
                    // style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="process-second-section-block-content-container"
                      //   style={{ backgroundColor: "white" }}
                    >
                      <div className="process-second-section-block-heading">
                        <h4>5. Polishing</h4>
                      </div>
                      <div className="process-second-section-block-content2">
                        <p>
                          The final step involves polishing the bonded areas to
                          a natural, tooth-like sheen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default BondingProcess;
