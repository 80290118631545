import React, { useEffect } from "react";
import arrow from "../../Assets-Dental/arrow.svg";
import Img from "../../Assets-Dental/removal-image.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import img from "../../Assets-Dental/Line 10.svg";
import { animateScroll as scroll } from "react-scroll";

const RestorativeDentistry = () => {
  useEffect(() => {
    document.title =
      " Restorative Dentistry By Dr Luisa in Redditch & Birmingham";
  }, []); //
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <div className="about-flex-container1 treatcontainer-merging-hmm">
        <div className="about-full-conatiner-contained">
          <div className="about-container-contained">
            <div
              className="about-container-left"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="about-container-contained-leftt">
                {/* <div className="set-flex-flux">
                  <div className="flex-title-set-flex">
                    <div className="img-line-container-flex">
                      <img src={img} alt="" />
                    </div>

                    <div className="about-heading-invis-dental-para para-dental-flex">
                      Restorative Dentistry
                      <br /> By Dr Luisa in Redditch & Birmingham
                    </div>
                  </div>
                </div> */}
                <div className="about-heading-invis-dental-enlight">
                  <h2>
                    Rejuvenating Your Smile with{" "}
                    <span style={{ color: "#D521A0" }}> Expert </span>
                    Care
                  </h2>
                </div>
                <div className="about-para-invis">
                  <p>
                    Dr Luisa's passion for restorative dentistry drives her to
                    offer the best advanced solutions to restore function,
                    comfort, and aesthetics to your smile. Whether it's
                    replacing missing teeth or repairing damage, Dr Luisa's aim
                    is to help you achieve optimal oral health.
                  </p>
                  <div className="about-button">
                    <Link to="/contact">
                      <button onClick={scrollToTop}>
                        Get In Touch
                        <img src={arrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="about-container-right"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="about-right-img11">
                <img src={Img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestorativeDentistry;
