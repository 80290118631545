import React, { useEffect } from "react";
import Line from "../../Assets-Dental/Line 10.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
const FAWhat = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  return (
    <div>
      {" "}
      <div className="lusia-conatiner-offer">
        <div className="lusia-container-full-contained-offer">
          <div className="lusia-left-offer1">
            <div
              className="lusia-contained-offer"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    What Are Facial Aesthetics?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight">
                <p>
                  Facial aesthetics comprise of non-surgical treatments that aim
                  to soften expression lines, define facial contours, and
                  provide a youthful glow. Using products like wrinkle-relaxing
                  injections and dermal fillers, these treatments can help to
                  restore and enhance natural beauty.
                </p>
              </div>
              <div className="set-tive"></div>
              <div className="about-button">
                <Link to="/contact">
                  <button>
                    Get In Touch
                    <img src={arrow} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="lusia-right-offer1"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="700"
          >
            <div
              className="lusia-contained-offer"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    Why Consider Facial Aesthetics?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight">
                <p>
                  Our facial treatments are chosen by those looking to reduce
                  signs of ageing or improve specific features without having to
                  look at undertaking invasive surgery. They offer a subtle yet
                  effective solution, with minimal downtime and maximum
                  convenience.
                </p>
              </div>
              {/* <div className="about-button">
  <button>
  
  </button>
</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAWhat;
