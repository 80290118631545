import React, { useEffect } from "react";
import arrow2 from "../../Assets-Dental/arrow-circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const FAProcess = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      {" "}
      <>
        <div className="process-flex">
          <div className="process-main-dental">
            <div className="process-main-inside">
              <div
                className="process-first-section process-flex-hmm-ok"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="dental-flex-hhh">
                  <div className="process-first-section-heading-dental-pro fa-hmm-heading">
                    <h2>Dr Luisa's Approach to Your Aesthetic Enhancement</h2>
                  </div>
                </div>
                <div className="process-para-contained">
                  <div className="process-first-section-content-pro">
                    <p>
                      Dr Luisa combines an in-depth understanding of facial
                      anatomy with a delicate touch to achieve results that are
                      as impressive as they are natural. All with a focus on
                      creating a harmonious balance that respects your unique
                      features.
                    </p>
                    {/* <p style={{ color: "transparent" }} className="p-remove">
        As an experienced Invisalign® provider, Dr Luisa, utilizes a
        combination of advanced 3D imaging technology and a
        personalized treatment plan to ensure that your journey to a
        straighter smile is smooth and efficient.
      </p> */}
                    <p>
                      Whether you’re seeking to refresh your look or considering
                      facial aesthetic treatments for the first time, get in
                      touch to schedule your personal consultation.
                    </p>
                  </div>
                </div>
                <div className="process-first-sectiion-button">
                  <button>
                    Book an appointment <img src={arrow2} alt="arrow" />
                  </button>
                </div>
              </div>

              <div
                className="process-second-section"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="process-second-section-block-container">
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>1. Consultation</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          Dr Luisa will discuss your aesthetic goals, assess
                          your skin condition, and create a bespoke treatment
                          plan for you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading wrinkle-flex-heading">
                        <h4>2. Wrinkle Relaxing Injections</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          Perfect for reducing the appearance of lines and
                          wrinkles for smoother, more youthful skin.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>3. Dermal Fillers </h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          These add volume, sculpt facial contours, and enhance
                          lips, subtly transforming and rejuvenating your
                          appearance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>4. Tailored Treatments</h4>
                      </div>
                      <div className="process-second-section-block-content1">
                        <p>
                          Dr Luisa offers a range of bespoke services designed
                          to target your specific aesthetic concerns.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div
                                        className="process-second-section-block "
                                    // style={{ backgroundColor: "white" }}
                                    >
                                        <div
                                            className="process-second-section-block-content-container"
                                        //   style={{ backgroundColor: "white" }}
                                        >
                                            <div className="process-second-section-block-heading">
                                                <h4>5. Dentures</h4>
                                            </div>
                                            <div className="process-second-section-block-content2">
                                                <p>
                                                    Whether partial or full, our dentures are crafted to provide a comfortable fit and a natural look.
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default FAProcess;
