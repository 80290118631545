import React, { useState } from "react";
import "./Gallery.css";
import "./responsive.css";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";

import Line from "../../Assets-Dental/Line 10.svg";
import Img1 from "../../Assets-Teeth/1.png";
import Img2 from "../../Assets-Teeth/2.png";
import Img3 from "../../Assets-Teeth/3.png";
import Img4 from "../../Assets-Teeth/4.png";
// import Img5 from "../../Assets-Teeth/5.png";
import Img6 from "../../Assets-Teeth/6.png";
import Img7 from "../../Assets-Teeth/7.png";
import Img8 from "../../Assets-Teeth/8.png";
import Img9 from "../../Assets-Teeth/9.png";
import Img10 from "../../Assets-Teeth/10.jpeg";
import Img11 from "../../Assets-Teeth/11.jpeg";
import Img12 from "../../Assets-Teeth/12.jpeg";
import Img13 from "../../Assets-Teeth/13.jpeg";
import Img14 from "../../Assets-Teeth/14.jpeg";
import Img15 from "../../Assets-Teeth/15.jpeg";
import Img16 from "../../Assets-Teeth/16.png";
import Img17 from "../../Assets-Teeth/17.png";
import Img18 from "../../Assets-Teeth/18.png";
import Img19 from "../../Assets-Teeth/19.png";
import Img20 from "../../Assets-Teeth/20.jpeg";
import Img21 from "../../Assets-Teeth/21.jpeg";
import Img22 from "../../Assets-Teeth/22.jpeg";
import Img23 from "../../Assets-Teeth/23.jpeg";
import Img24 from "../../Assets-Teeth/24.jpeg";
import Img25 from "../../Assets-Teeth/25.jpeg";
import Img26 from "../../Assets-Teeth/26.jpeg";
import Img28 from "../../Assets-Teeth/27.jpeg";
import Img29 from "../../Assets-Teeth/28.jpeg";
import Img30 from "../../Assets-Teeth/29.jpeg";
import Img31 from "../../Assets-Teeth/30.jpeg";
import Img32 from "../../Assets-Teeth/31.jpeg";
import Img33 from "../../Assets-Teeth/32.jpeg";
import Img34 from "../../Assets-Teeth/33.jpeg";
import Img35 from "../../Assets-Teeth/34.jpeg";
import Img36 from "../../Assets-Teeth/35.jpeg";
import Img37 from "../../Assets-Teeth/36.jpeg";
import Img38 from "../../Assets-Teeth/37.jpeg";
import Img39 from "../../Assets-Teeth/38.jpeg";
import Img40 from "../../Assets-Teeth/39.jpeg";
import Img41 from "../../Assets-Teeth/40.jpeg";
import Img42 from "../../Assets-Teeth/41.jpeg";
import Img43 from "../../Assets-Teeth/42.jpeg";
import Img44 from "../../Assets-Teeth/43.jpeg";
import Img45 from "../../Assets-Teeth/44.jpeg";
import Img46 from "../../Assets-Teeth/45.jpeg";
import Img47 from "../../Assets-Teeth/46.jpeg";
import Img48 from "../../Assets-Teeth/47.jpeg";
import Img50 from "../../Assets-Teeth/48.jpeg";
import Img51 from "../../Assets-Teeth/49.jpeg";
import Img52 from "../../Assets-Teeth/50.jpeg";
import Img53 from "../../Assets-Teeth/51.jpeg";
import Img54 from "../../Assets-Teeth/52.jpeg";
import Img55 from "../../Assets-Teeth/53.jpeg";
import Img56 from "../../Assets-Teeth/54.jpeg";
import Img57 from "../../Assets-Teeth/55.jpeg";
import Img58 from "../../Assets-Teeth/56.jpeg";
import Img59 from "../../Assets-Teeth/57.jpeg";
import Img60 from "../../Assets-Teeth/58.jpeg";
import Img61 from "../../Assets-Teeth/59.jpeg";
import Img62 from "../../Assets-Teeth/60.jpeg";
import Img63 from "../../Assets-Teeth/61.jpeg";
import Img64 from "../../Assets-Teeth/62.jpeg";
import Img65 from "../../Assets-Teeth/63.jpeg";
import Img66 from "../../Assets-Teeth/64.jpeg";
import Img67 from "../../Assets-Teeth/65.jpeg";
import Img68 from "../../Assets-Teeth/66.jpeg";
// import Img69 from "../../Assets-Teeth/67.jpeg";
import Img70 from "../../Assets-Teeth/68.jpeg";
import Img71 from "../../Assets-Teeth/69.png";
import Img72 from "../../Assets-Teeth/70.png";
import Img73 from "../../Assets-Teeth/extra 71.png";
import Img74 from "../../Assets-Teeth/Extra 72.jpg";
import Img75 from "../../Assets-Teeth/extra 73.jpg";
import Img76 from "../../Assets-Teeth/extra 74.jpg";
import Img77 from "../../Assets-Teeth/extra 75.jpg";
import Img78 from "../../Assets-Teeth/extra 76.jpg";
import Img79 from "../../Assets-Teeth/extra 77.jpg";

const Gallery = () => {
  const [showAllImages, setShowAllImages] = useState(false);

  const allImages = [
    Img1,
    Img2,
    Img3,
    Img4,
    // Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,
    Img16,
    Img17,
    Img18,
    Img19,
    Img20,
    Img21,
    Img22,
    Img23,
    Img24,
    Img25,
    Img26,
    Img28,
    Img29,
    Img30,
    Img31,
    Img32,
    Img33,
    Img34,
    Img35,
    Img36,
    Img37,
    Img38,
    Img39,
    Img40,
    Img41,
    Img42,
    Img43,
    Img44,
    Img45,
    Img46,
    Img47,
    Img48,
    Img50,
    Img51,
    Img52,
    Img53,
    Img54,
    Img55,
    Img56,
    Img57,
    Img58,
    Img59,
    Img60,
    Img61,
    Img62,
    Img63,
    Img64,
    Img65,
    Img66,
    Img67,
    Img68,
    // Img69,
    Img70,
    Img71,
    Img72,
    Img73,
    Img74,
    Img75,
    Img76,
    Img77,
    Img78,
    Img79,
  ];

  const displayedImages = showAllImages ? allImages : allImages.slice(0, 6);

  const handleToggleImages = () => {
    if (showAllImages) {
      setShowAllImages(!showAllImages);
      // Scroll to top only when showing less
      scrollToTop();
    } else {
      setShowAllImages(!showAllImages);
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div className="gallery-container-contined-container container-testim-motivate">
      <div className="gallery-container">
        <div className="expert-content ">
          <div className="expert-img gallery-set">
            <img src={Line} alt="" />
            <div className="expert-heading-control">Dr Luisa Mateescu</div>
            <img src={Line} alt="" style={{ marginLeft: "1%" }} />
          </div>
        </div>
        <div className="gallery-heading">
          <h2>Smile Gallery</h2>
        </div>

        <div className="area-imgg">
          {displayedImages.map((img, index) => (
            <div className="gallery-images-single" key={index}>
              <img src={img} alt="" />
            </div>
          ))}
        </div>
        <div className="button-gallery-contained">
          <button onClick={handleToggleImages}>
            {showAllImages ? "Show less" : "Show more"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
