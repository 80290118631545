import React, { useEffect } from "react";
import Testimonials from "../Testimonials/Testimonials";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Gallery from "../Gallery-testi/Gallery";
import Church from "../Church/Church";
import TestiContact from "../Testimonial-Contact/TestiContact";

const TestimonialsPage = () => {
  useEffect(() => {
    document.title = "Dr Luisa: The Pink Dentist";
  }, []); //
  return (
    <div>
      <div style={{ overflow: "hidden", width: "100%" }}>
        <Navbar />
        <Gallery />
        <Testimonials />
        <Church />
        <TestiContact />
        <Footer />
      </div>
    </div>
  );
};

export default TestimonialsPage;
