import React, { useEffect } from "react";
import "./About.css";
import "./responsive.css";
import arrow from "../../Assets-Dental/arrow.svg";
import Img from "../../Assets-Dental/about-ing1.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";

const About = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {/* <Navbar /> */}
      <div className="about-flex-container treatcontainer-merging-hgzxdjh">
        <div className="about-full-conatiner-contained">
          <div className="about-container-contained">
            <div
              className="about-container-left"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="about-container-contained-leftt">
                <div className="about-heading">
                  <h2>
                    More About{" "}
                    <span style={{ color: "#D521A0" }}> Dr Luisa</span>{" "}
                  </h2>
                </div>
                <div className="about-para">
                  <p style={{ color: "white" }}></p>
                  <div className="about-button">
                    <Link to="/contact">
                      <button onClick={scrollToTop}>
                        Get In Touch <img src={arrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="about-container-right"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="about-right-img">
                <img src={Img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
