import React, { useEffect } from "react";
import arrow from "../../Assets-Dental/arrow.svg";
import Img from "../../Assets-Dental/removal-image.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import img from "../../Assets-Dental/Line 10.svg";
import { animateScroll as scroll } from "react-scroll";

const BondingInvis = () => {
  useEffect(() => {
    document.title = "Composite Bonding By Dr Luisa in Redditch & Birmingham";
  }, []); //
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <div className="about-flex-container1 treatcontainer-merging-hmm">
        <div className="about-full-conatiner-contained">
          <div className="about-container-contained">
            <div
              className="about-container-left"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="about-container-contained-leftt">
                {/* <div className="set-flex-flux">
                  <div className="flex-title-set-flex">
                    <div className="img-line-container-flex">
                      <img src={img} alt="" />
                    </div>

                    <div className="about-heading-invis-dental-para para-dental-flex">
                      Composite Bonding <br />
                      By Dr Luisa in Redditch & Birmingham
                    </div>
                  </div>
                </div> */}
                <div className="about-heading-invis-dental-enlight">
                  <h2>
                    Sculpting Smiles with{" "}
                    <span style={{ color: "#D521A0" }}> Precision </span> and
                    Artistry{" "}
                  </h2>
                </div>
                <div className="about-para-invis">
                  <p>
                    Dr Luisa offers composite bonding as a versatile solution to
                    a variety of cosmetic dental concerns. This minimally
                    invasive treatment can significantly enhance the aesthetics
                    of your smile with a natural-looking finish.
                  </p>
                  <div className="about-button">
                    <Link to="/contact">
                      <button onClick={scrollToTop}>
                        Get In Touch <img src={arrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="about-container-right"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="about-right-img11">
                <img src={Img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BondingInvis;
