// Icon.js
import React from "react";

const Icon = ({ isActive, isTreatments }) => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: "pointer",
        stroke: isTreatments ? (isActive ? "black" : "black") : "white",
      }}
    >
      <path
        d="M1 1L6 6L11 1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
