import React, { useEffect } from "react";
import "./Leader.css";
import "./responsive.css";
import remote from "../../Assets-Dental/remote.png";
import remote1 from "../../Assets-Dental/remote-rename.png";
import leader from "../../Assets-Dental/leader.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import Line from "../../Assets-Dental/line2.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Leader = () => {
  return (
    <div className="leader-container-flex">
      <div className="leader-container">
        <div className="leader-left">
          <div className="leader-left-img">
            <img src={remote} alt="" />
          </div>
          <div className="leader-left-img10">
            <img src={remote1} alt="" />
          </div>

          <div className="leader-content-container">
            <div className="leading-heading-flex">
              <div
                className="leader-heading"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <h2>The Regional Enlighten Centre of Excellence</h2>
              </div>
            </div>
            <div className="leader-para-dn">
              <div
                className="leader-paragraph"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <p>
                  Only the best providers become Enlighten Regional Centers of
                  Excellence so Dr. Luisa takes great pride in being selected
                  for the region she works in
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="leader-right">
          <div className="leader-right-image">
            <img src={leader} alt="" />
          </div>
          <div className="leader-content-container-right">
            <div
              className="leader-heading-right"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content3">
                <div className="expert-img3">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control3">Dental Expert</div>
                  <img src={Line} alt="" />
                </div>
              </div>
              <h2>Dental Leader’s Podcast</h2>
            </div>
            <div
              className="about-button1"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <a href="https://open.spotify.com/episode/2mb9NZlLzMcMGi4pK8HqPF?si=fAo3ExEcT9Gn_FAQH0FfCw">
                <button>
                  Listen Now <img src={arrow} alt="" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leader;
