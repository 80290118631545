import React from "react";
import NavbarTreat from "../Navbar-treat/NavbarTreat";
import InvisEnlighten from "../Invis-englighten/InvisEnlighten";
import EnlightOral from "../Enlighten-oral/EnlightOral";
import ContactTreat from "../Contact-treat/ContactTreat";
import Footer from "../Footer/Footer";
import Teeth from "../Teeth/Teeth";
import EnlightProcess from "../Enlight-process/EnlightProcess";
import EnlightStory from "../Enlight-Story/EnlightStory";

const Englighten = () => {
  return (
    <div>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <NavbarTreat />
        <InvisEnlighten />
        <EnlightOral />
        <Teeth />
        <EnlightProcess />
        <EnlightStory />
        <ContactTreat />
        <div style={{ marginTop: "4%" }}></div>
        <Footer />
      </div>
    </div>
  );
};

export default Englighten;
