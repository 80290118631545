import React, { useEffect } from "react";
import "./Expert.css";
import "./responsive.css";
import injection from "../../Assets-Dental/inj.png";
import Line from "../../Assets-Dental/Line 10.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Expert = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  return (
    <div className="expert-content-container-full-responsive">
      <div className="expert-content-container-full">
        <div className="expert-container">
          <div className="expert-container-contained">
            <div
              className="expert-left"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-left-contained">
                <img src={injection} alt="" />
              </div>
            </div>
            <div
              className="expert-right"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-right-content-container">
                {" "}
                <div className="expert-content-container">
                  <div className="expert-content">
                    <div className="expert-img">
                      <img src={Line} alt="" />
                      <div className="expert-heading-control">
                        Dental Expert
                      </div>
                    </div>
                  </div>
                </div>
                <div className="expert-heading-container">
                  <p>
                    Dr Luisa Mateescu qualified in Romania in 2017 at the
                    Faculty of Dentistry, University of Medicine and Pharmacy
                    ‘Carol Davila’ in Bucharest.
                  </p>
                </div>
                <div className="expert-para-container para-setssss">
                  <p>
                    She started her career alongside oro-maxillo-facial surgeons
                    and endodontic specialists within a private environment.
                  </p>
                </div>
                <div className="expert-para-container para-setssss1">
                  <p>
                    In 2018 Luisa relocated to the UK, starting as an NHS mentee
                    in a mixed NHS and private practice. Luisa relocated to the
                    West Midlands where she currently works at {"{my}"}dentist
                    Kings Norton and AllSaints Dental in Kings Heath,
                    Birmingham. Since then, Luisa has completed Dominic
                    Hassall's Postgraduate Certificate in Contemporary
                    Restorative and Aesthetic Dentistry and has also became an
                    accredited NHS and Private mentor. She is also a {"{my}"}
                    dentist Connect coach, lecturer and demonstrator as part of
                    Glo Academy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expert;
