import React from "react";
import Navbar from "../Navbar/Navbar";
import DentalInvis from "../Dental-invis/DentalInvis";
import Oral from "../Dental-Oral/Oral";
import Footer from "../Footer/Footer";
import Teeth from "../Teeth/Teeth";
import DentalProcess from "../Dental-process/DentalProcess";
import DentalStory from "../Dental-story/DentalStory";
import NavbarTreat from "../Navbar-treat/NavbarTreat";
import ContactTreat from "../Contact-treat/ContactTreat";

const GeneralDensityPage = () => {
  return (
    <div>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <NavbarTreat />
        <DentalInvis />
        <Oral />
        <Teeth />
        <DentalProcess />
        <DentalStory />
        <ContactTreat />
        <div style={{ marginBottom: "4%" }}></div>
        <Footer />
      </div>
    </div>
  );
};

export default GeneralDensityPage;
