import React from "react";
import NavbarTreat from "../Navbar-treat/NavbarTreat";
import Footer from "../Footer/Footer";
import RCTop from "../RooCTop/RCTop";
import RootCWhat from "../RootCanalwhat/RootCWhat";
import Teeth from "../Teeth/Teeth";
import RootCProcess from "../RootCanalProcess/RootCProcess";
import ContactTreat from "../Contact-treat/ContactTreat";
import RCStory from "../RootCanalStory/RCStory";

export default function RootCanalmain() {
  return (
    <div>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <NavbarTreat />
        <RCTop />
        <RootCWhat />
        <Teeth />
        <RootCProcess />
        <RCStory />
        <ContactTreat />
        <div style={{ marginTop: "4%" }}></div>
        <Footer />
      </div>
    </div>
  );
}
