import React, { useEffect } from "react";
import arrow from "../../Assets-Dental/arrow.svg";
import Img from "../../Assets-Dental/invis-removebg-preview.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const FATop = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  return (
    <div>
      {" "}
      <div className="about-flex-container1">
        <div className="about-full-conatiner-contained">
          <div className="about-container-contained">
            <div
              className="about-container-left"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="about-container-contained-leftt">
                <div className="about-heading-invis-dental-enlight">
                  <h2>
                    Enhancing Natural Beauty with{" "}
                    <span style={{ color: "#D521A0" }}> Professional </span>
                    Care
                  </h2>
                </div>
                <div className="about-para-invis">
                  <p>
                    Dr Luisa has trained to extend her commitment to aesthetic
                    excellence beyond the smile. Her facial aesthetics services
                    are designed to rejuvenate and enhance your natural
                    features, providing a refreshed and youthful appearance.
                  </p>
                  <div className="about-button">
                    <Link to="/contact">
                      <button>
                        Get In Touch
                        <img src={arrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="about-container-right"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="about-right-img11">
                <img src={Img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FATop;
