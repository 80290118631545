import React, { useEffect } from "react";
import Line from "../../Assets-Dental/Line 10.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";

const EnlightOral = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <div className="lusia-conatiner-offer">
        <div className="lusia-container-full-contained-offer">
          <div className="lusia-left-offer1">
            <div
              className="lusia-contained-offer"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    What is Teeth Whitening?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight">
                <p>
                  Teeth whitening is a cosmetic dental procedure aimed at
                  removing stains and discolouration from teeth and improving
                  their colour through safe, dental-grade whitening agents. It's
                  a popular and efficient way to combat the effects of ageing,
                  diet, and lifestyle on the colour of your teeth.
                </p>
              </div>
              <div className="set-tive"></div>
              <div className="about-button">
                <Link to="/contact">
                  <button onClick={scrollToTop}>
                    Get In Touch
                    <img src={arrow} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="lusia-right-offer1"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="700"
          >
            <div
              className="lusia-contained-offer res-bes"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    Why Choose Professional Whitening?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight ">
                <p>
                  Whilst there are many over-the-counter options available,
                  professional whitening offers safer, more reliable, and
                  longer-lasting results under the guidance of a dental
                  professional. The whitening process is tailored to your
                  individual needs, ensuring your teeth and gums remain healthy
                  throughout the treatment.
                </p>
              </div>
              {/* <div className="about-button">
          <button>
     
          </button>
        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnlightOral;
