import React, { useEffect } from "react";
import arrow2 from "../../Assets-Dental/arrow-circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const DentalProcess = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <div className="process-flex">
        <div className="process-main-dental">
          <div className="process-main-inside">
            <div
              className="process-first-section"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="dental-flex-hhh">
                <div className="process-first-section-heading-dental">
                  <h3>Dr Luisa's Approach to Dental Wellness</h3>
                </div>
              </div>
              <div className="process-para-contained">
                <div className="process-first-section-content">
                  <p>
                    Dr Luisa believes in a proactive and preventative approach
                    to dental care. Using the latest technology to provide
                    efficient, effective treatments, whilst providing a
                    comfortable experience for patients of all ages.
                  </p>
                  {/* <p style={{ color: "transparent" }} className="p-remove">
                    As an experienced Invisalign® provider, Dr Luisa, utilizes a
                    combination of advanced 3D imaging technology and a
                    personalized treatment plan to ensure that your journey to a
                    straighter smile is smooth and efficient.
                  </p> */}
                  <p>
                    Ready for a dental check-up or need a trusted dentist for
                    your family? Get in touch to book an appointment and
                    experience the difference of dedicated dental care.
                  </p>
                </div>
              </div>
              <div className="process-first-sectiion-button">
                <Link
                  to="/contact"
                  style={{ textDecoration: "none" }}
                  onClick={scrollToTop}
                >
                  <button>
                    Book an appointment <img src={arrow2} alt="arrow" />
                  </button>
                </Link>
              </div>
            </div>

            <div
              className="process-second-section"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="process-second-section-block-container">
                <div className="process-second-section-block">
                  <div className="process-second-section-block-content-container">
                    <div className="process-second-section-block-heading">
                      <h4>1. Examinations</h4>
                    </div>
                    <div className="process-second-section-block-content">
                      <p>
                        A thorough check-up, including oral cancer screenings
                        and digital X-rays, to monitor your oral health.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-second-section-block">
                  <div className="process-second-section-block-content-container">
                    <div className="process-second-section-block-heading">
                      <h4>2. Hygiene Services</h4>
                    </div>
                    <div className="process-second-section-block-content">
                      <p>
                        A professional cleaning to remove plaque and tartar,
                        helping to prevent gum disease and decay.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-second-section-block">
                  <div className="process-second-section-block-content-container">
                    <div className="process-second-section-block-heading">
                      <h4>3. Fillings</h4>
                    </div>
                    <div className="process-second-section-block-content">
                      <p>
                        The use high-quality materials to treat cavities,
                        restoring the health and function of your teeth.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-second-section-block">
                  <div className="process-second-section-block-content-container">
                    <div className="process-second-section-block-heading">
                      <h4>4. Crowns and Bridges</h4>
                    </div>
                    <div className="process-second-section-block-content1">
                      <p>
                        For damaged or missing teeth, custom crowns and bridges
                        that look natural and restore your smile’s integrity.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-second-section-block">
                  <div className="process-second-section-block-content-container">
                    <div className="process-second-section-block-heading">
                      <h4>5. Family Dentistry</h4>
                    </div>
                    <div className="process-second-section-block-content2">
                      <p>
                        Dr Luisa offers treatments for patients of all ages,
                        focusing on education and prevention for the youngest
                        visitors.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DentalProcess;
