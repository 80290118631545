import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Page from "./Dental-Components/Page/Page";
import Testimonials from "./Dental-Components/Testimonials/Testimonials";

import Aboutpage from "./Dental-Components/About-page/Aboutpage";
import TreatmentPage from "./Dental-Components/TreatmentPage/TreatmentPage";
import GeneralDensityPage from "./Dental-Components/GeneralDensityPage/GeneralDensityPage";
import Englighten from "./Dental-Components/EnglightenPage/Englighten";
import BondingPage from "./Dental-Components/Bonding-page/BondingPage";
import Procelain from "./Dental-Components/Procelain-page/Procelain";
import RestorativeDmain from "./Dental-Components/RestorativeD-main/RestorativeD-main";
import RootCanalmain from "./Dental-Components/RootCanal-main/RootCanalmain";
import IMmain from "./Dental-Components/InvisalignMain/IMmain";
import FacialAMain from "./Dental-Components/Facial-Aesthetics/FacialAMain";
import TestimonialsPage from "./Dental-Components/TestimonialsPage/TestimonialsPage";
import Touch from "./Dental-Components/Touch/Touch";
import TouchPage from "./Dental-Components/TouchPahe/TouchPage";
import Privacy from "./Privacy/Privacy";
import PrivacyPage from "./PrivacyPage/PrivacyPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Page />} />
        {/* <Route index element={<Page />} /> */}
        <Route exact path="/about" element={<Aboutpage />} />
        <Route exact path="/testimonials" element={<TestimonialsPage />} />
        <Route exact path="/treatment" element={<TreatmentPage />} />
        <Route exact path="/generaldensity" element={<GeneralDensityPage />} />
        <Route exact path="/englightent" element={<Englighten />} />
        <Route exact path="/bonding" element={<BondingPage />} />
        <Route exact path="/procelain" element={<Procelain />} />
        <Route exact path="/restorative" element={<RestorativeDmain />} />
        <Route exact path="/root" element={<RootCanalmain />} />
        <Route exact path="/invisalign" element={<IMmain />} />
        <Route exact path="/facial" element={<FacialAMain />} />
        <Route exact path="/contact" element={<TouchPage />} />
        {/* <Route exact path="/privacy" element={<Privacy />} /> */}
        <Route exact path="/privacypage" element={<PrivacyPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
