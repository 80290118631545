import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";
// import logoimge from "../../Assets-Dental/real-logo-ori.png";
import logoimge from "../../Assets-Dental/white-logo.png";
import msgicon from "../../Assets-Dental/message-footer.svg";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";
import { Link } from "react-router-dom";
import facebook from "../../Assets-Dental/self.png";

export default function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  useEffect(() => {
    // Update the current year when the component mounts
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <>
      <div className="Footer">
        <div className="Footer-main-container">
          <div className="Footer-upper-section">
            <div className="footer-upper-section-logo">
              <img src={logoimge} alt="logooo" />
            </div>
            <div className="footer-upper-section-line"></div>
            <div className="Footer-upper-section-timing">
              <div className="Days">
                <p>Monday - Friday</p>
              </div>
              <div className="Line">|</div>
              <div className="Time">
                <p>9 AM - 5 PM</p>
              </div>
            </div>
            <div className="Footer-upper-section-email">
              <div className="Email">
                <p>
                  <img src={msgicon} alt="msgicon" className="msgicon-email" />{" "}
                  Email
                </p>
              </div>
              <div className="Line1">|</div>
              <div className="Emailname">
                <p>hello@drluisa.co.uk</p>
              </div>
            </div>
          </div>
          <div className="insta-btn-set">
            <div className="insta-img-sett ">
              {" "}
              <a href="https://www.instagram.com/the.pink.dentist">
                <img src={facebook} alt="" />
              </a>
            </div>
          </div>
          <div className="Footer-lower-section">
            <div className="footer-flex-flus">
              <div className="Footer-lower-section-menu">
                <div className="insta-img-sett flex-set-hex">
                  {" "}
                  <a href="https://www.instagram.com/the.pink.dentist">
                    <img src={facebook} alt="" />
                  </a>
                </div>
                {/* <div className="profile-setup"> */}
                <Link
                  to="/privacypage"
                  style={{ textDecoration: "none", color: "white" }}
                  onClick={scrollToTop}
                  className="profile-setup"
                >
                  Privacy & Cookie Policy
                </Link>
                {/* </div> */}
              </div>
            </div>
            <div className="lower-buttons">
              <div className="Footer-upper-section-timing2">
                <div className="Days">
                  <p>Monday - Friday</p>
                </div>
                <div className="Line2">|</div>
                <div className="Time">
                  <p>9 AM - 5 PM</p>
                </div>
              </div>
              <div className="Footer-upper-section-email2">
                <div className="Email">
                  <p>
                    <img src={msgicon} alt="msgicon" /> Email
                  </p>
                </div>
                <div className="Line1">|</div>
                <div className="Emailname">
                  <p>hello@drluisa.co.uk</p>
                </div>
              </div>
            </div>
            <div className="Footer-copyright">
              <div className="copyright-data">
                <p>© 2024 Copyright. All rights reserved</p>
                <p>
                  Designed and maintained by{" "}
                  <a
                    href="https://smile-mc.co.uk"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Smile Marketing & Consultancy
                  </a>
                </p>
              </div>
            </div>
            <div className="Footer-lower-section-email">
              <div className="Email">
                <p>
                  <img src={msgicon} alt="msgicon" /> Email
                </p>
              </div>
              <div className="Line">|</div>
              <div className="Emailname">
                <p>hello@drluisa.co.uk</p>
              </div>
            </div>
          </div>
          <div className="lower-copyright">
            <div className="lower-nsta-set-max">
              <div className="insta-img-sett set-insta-face">
                {" "}
                <a href="https://www.instagram.com/the.pink.dentist">
                  <img src={facebook} alt="" />
                </a>
              </div>
              <div className="copyright-data">
                <p>© {currentYear} Copyright. All rights reserved</p>
                <p className="parraaaa-set">
                  Designed and maintained by{" "}
                  <a
                    href="https://smile-mc.co.uk"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Smile Marketing & Consultancy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
