import React, { useState, useEffect } from "react";
import "./Testimonials.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import rating from "../../Assets-Dental/rating.png";
import Img1 from "../../Assets-Dental/pic1.svg";
import Img2 from "../../Assets-Dental/pic2.svg";
import Img3 from "../../Assets-Dental/pic3.png";
import left from "../../Assets-Dental/arrow-left.svg";
import right from "../../Assets-Dental/arrow-right.svg";
import ImgFree1 from "../../Assets-Dental/ImgTesti.jpg";
import ImgFree2 from "../../Assets-Dental/ImgFree5.jpg";
import ImgFree3 from "../../Assets-Dental/ImgFree6.jpg";
import ImgFree4 from "../../Assets-Dental/4.jpg";
import ImgFree7 from "../../Assets-Dental/10.jpg";
import "./responsive.css";

const Testimonials = () => {
  const [slider, setSlider] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(3);

  const [activeArrow, setActiveArrow] = useState("next");

  const handleNext = () => {
    if (slider) {
      slider.slickNext();
      setActiveArrow("next");
    }
  };

  const handlePrev = () => {
    if (slider) {
      slider.slickPrev();
      setActiveArrow("prev");
    }
  };
  useEffect(() => {
    const handleResize = () => {
      // Update slidesToShow based on window width
      if (window.innerWidth <= 738) {
        setSlidesToShow(1);
      } else if (window.innerWidth <= 950) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(3);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial call to set slidesToShow based on current window width
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    infinite: true,
    speed: 800,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    // autoplay: true,
    arrows: false,
  };
  return (
    <>
      {" "}
      <div className="testimonial-container">
        <div className="test-container-full-contained">
          <div className="testi-full-container">
            <div className="testi-containerr-contained">
              {/* <div
                className="testi-heading"
              >
                <h2>Customer experiences Dr.Luisa</h2>
              </div> */}
              <div
                className="testi-para"
                // data-aos="zoom-in"
                // data-aos-duration="800"
              >
                <h2>People trust Dr. Luisa</h2>
              </div>
              <div className="testimon-para">
                <p>
                  Have a look at the feedback from Dr Luisa’s patients following
                  their treatments.
                </p>
              </div>
            </div>
            <div
              className="slider-container"
              // data-aos="zoom-in"
              // data-aos-duration="800"
            >
              <div className="gradiant1">
                <div className="g-card1"></div>
              </div>
              <div className="slider-flex-main">
                <div className="slider-flex">
                  <Slider ref={(c) => setSlider(c)} {...settings}>
                    <div className="slider-item">
                      <div className="slider-img">
                        <img src={rating} alt="" />
                      </div>
                      {/* <div className="p-para-test-again"> */}
                      <div className="p-para-test">
                        <div className="p-para">
                          <p>
                            <span>
                              Two fillings today. No local at my request, didn’t
                              feel a thing. Luisa and nurse very mindful of my
                              disability and issues with swallowing and fear of
                              choking. Couldn’t have had better treatment.{" "}
                              <span style={{ color: "white" }}>
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Hic perspiciatis fugiat
                                aliquam quia reprehenderit perferendis iure
                                dolor sit quis iusto! aliquam quia reprehenderit
                                perferendis iure dolor sit quis iusto!
                                perferendis iure dolor sit quis iusto!
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="left-section-content-test">
                        <div className="left-section-content-container-test">
                          <div className="le-test">
                            <div className="le-image-test">
                              <img src={ImgFree4} alt="" />
                            </div>
                          </div>
                          <div className="re-test">
                            <div className="re-heading-test">Anon</div>
                            {/* <div className="re-para-test">Digital Marketer</div> */}
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className="slider-item">
                      <div className="slider-img">
                        <img src={rating} alt="" />
                      </div>
                      {/* <div className="p-para-test-again"> */}
                      <div className="p-para-test1">
                        <div className="p-para">
                          <p>
                            <span>
                              I had dentures before but found them really
                              uncomfortable. My Dentist recommended a Maryland
                              Bridge. My first appointment was lovely, no pain
                              at all and my fitting of the bridge was perfect.
                              What a difference to dentures. I feel so pleased
                              my dentist and her assistant were really lovely
                              and professional.
                              <span style={{ color: "white" }}>
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Hic perspiciatis fugiat Lorem,
                                ipsum dolor sit amet consectetur
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="left-section-content-test">
                        <div className="left-section-content-container-test">
                          <div className="le-test">
                            <div className="le-image-test">
                              <img src={Img2} alt="" />
                            </div>
                          </div>
                          <div className="re-test">
                            <div className="re-heading-test">J Liza</div>
                            {/* <div className="re-para-test">
                              Freelance React Developer
                            </div> */}
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className="slider-item">
                      <div className="slider-img">
                        <img src={rating} alt="" />
                      </div>
                      {/* <div className="p-para-test-again"> */}
                      <div className="p-para-test2">
                        <div className="p-para">
                          <p>
                            <span>
                              Tooth extraction for my child was very smooth and
                              made my child very comfortable.
                              <span style={{ color: "white" }}>
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Hic perspiciatis fugiat
                                aliquam quia reprehenderit perferendis iure
                                dolor sit quis iusto! aliquam quia reprehenderit
                                perferendis iure dolor sit quis iusto!
                                perferendis iure dolor sit quis iusto! aliquam
                                quia reprehenderit perferendis iure dolor sit
                                quis iusto! aliquam quia reprehenderit
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="left-section-content-test">
                        <div className="left-section-content-container-test">
                          <div className="le-test">
                            <div className="le-image-test">
                              <img src={Img3} alt="" />
                            </div>
                          </div>
                          <div className="re-test">
                            <div className="re-heading-test">Jenny</div>
                            {/* <div className="re-para-test">Graphic Designer</div> */}
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className="slider-item">
                      <div className="slider-img">
                        <img src={rating} alt="" />
                      </div>
                      {/* <div className="p-para-test-again"> */}
                      <div className="p-para-test">
                        <div className="p-para">
                          <p>
                            <span>
                              Attend every 6 months for my check up. Receive a
                              high level of attention, advice on oral care,
                              excellent treatment if required.
                              <span style={{ color: "white" }}>
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Hic perspiciatis fugiat Lorem,
                                ipsum dolor sit amet consectetur Lorem, ipsum
                                dolor sit amet consectetur adipisicing elit. Hic
                                perspiciatis fugiat Lorem, ipsum dolor sit amet
                                consectetur Lorem, ipsum dolor sit amet
                                consectetur adipisicing elit. Hic perspiciatis
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="left-section-content-test">
                        <div className="left-section-content-container-test">
                          <div className="le-test">
                            <div className="le-image-test">
                              <img src={ImgFree1} alt="" />
                            </div>
                          </div>
                          <div className="re-test">
                            <div className="re-heading-test">Katherine</div>
                            {/* <div className="re-para-test">Digital Marketer</div> */}
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className="slider-item">
                      <div className="slider-img">
                        <img src={rating} alt="" />
                      </div>
                      {/* <div className="p-para-test-again"> */}
                      <div className="p-para-test">
                        <div className="p-para">
                          <p>
                            I couldn't be happier with the results from the
                            Enlighten tooth whitening treatment. Dr. Luisa and
                            her team made me feel incredibly welcome. Their
                            friendly and professional demeanour immediately put
                            me at ease. Dr. Luisa herself was extremely
                            knowledgeable and took the time to explain the
                            procedure in detail. The results of the tooth
                            whitening were fantastic -they were brighter and
                            whiter than ever. I would 100% recommend.
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="left-section-content-test">
                        <div className="left-section-content-container-test">
                          <div className="le-test">
                            <div className="le-image-test">
                              <img src={Img1} alt="" />
                            </div>
                          </div>
                          <div className="re-test">
                            <div className="re-heading-test">S Parker</div>
                            {/* <div className="re-para-test">Digital Marketer</div> */}
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className="slider-item">
                      <div className="slider-img">
                        <img src={rating} alt="" />
                      </div>
                      {/* <div className="p-para-test-again"> */}
                      <div className="p-para-test">
                        <div className="p-para">
                          <p>
                            <span>
                              I took my very nervous 9 year old to this dentist
                              and she was absolutely brilliant. Showed her
                              everything she was doing and put her at ease. I
                              even managed to get her to go back and see the
                              same dentist for a temporary filling which she
                              would never normally agree to! Quite pricey but
                              don't mind paying the price for them to be so kind
                              and put my child at ease.
                              <span style={{ color: "white" }}>
                                Lorem, ipsum dolor sit amet consectetur Lorem,
                                ipsum dolor sit amet consectetur
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="left-section-content-test">
                        <div className="left-section-content-container-test">
                          <div className="le-test">
                            <div className="le-image-test">
                              <img src={ImgFree7} alt="" />
                            </div>
                          </div>
                          <div className="re-test">
                            <div className="re-heading-test">Tony</div>
                            {/* <div className="re-para-test">Digital Marketer</div> */}
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className="slider-item">
                      <div className="slider-img">
                        <img src={rating} alt="" />
                      </div>
                      {/* <div className="p-para-test-again"> */}
                      <div className="p-para-test">
                        <div className="p-para">
                          <p>
                            <span>
                              Luisa was lovely put me straight at ease and was
                              very informative regarding my treatment.
                              <span style={{ color: "white" }}>
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Hic perspiciatis fugiat Lorem,
                                ipsum dolor sit amet consectetur Lorem, ipsum
                                dolor sit amet consectetur adipisicing elit. Hic
                                perspiciatis fugiat Lorem, ipsum dolor sit amet
                                consectetur Lorem, ipsum dolor sit amet
                                consectetur adipisicing elit. Hic perspiciatis
                                consectetur{" "}
                                <span className="okkkkkkkkk">
                                  adipisicing elit. Hic perspiciatis
                                </span>
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="left-section-content-test">
                        <div className="left-section-content-container-test">
                          <div className="le-test">
                            <div className="le-image-test">
                              <img src={ImgFree2} alt="" />
                            </div>
                          </div>
                          <div className="re-test">
                            <div className="re-heading-test">Su Hill</div>
                            {/* <div className="re-para-test">Digital Marketer</div> */}
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                    <div className="slider-item">
                      <div className="slider-img">
                        <img src={rating} alt="" />
                      </div>
                      {/* <div className="p-para-test-again"> */}
                      <div className="p-para-test">
                        <div className="p-para">
                          <p>
                            <span>
                              I am the world's worst patient as I'm petrified of
                              needles. I went to see Luisa and she was
                              absolutely brilliant. Such a nice lady. Thank you.
                              <span style={{ color: "white" }}>
                                Lorem, ipsum dolor sit amet consectetur
                                adipisicing elit. Hic perspiciatis fugiat Lorem,
                                ipsum dolor sit amet consectetur Lorem, ipsum
                                dolor sit amet consectetur adipisicing elit. Hic
                                perspiciatis fugiat Lorem, ipsum dolor sit amet
                                consectetur Lorem, ipsum dolor sit amet
                                consectetur adipisicing elit. Hic perspiciatis
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="left-section-content-test">
                        <div className="left-section-content-container-test">
                          <div className="le-test">
                            <div className="le-image-test">
                              <img src={ImgFree3} alt="" />
                            </div>
                          </div>
                          <div className="re-test">
                            <div className="re-heading-test">Zack</div>
                            {/* <div className="re-para-test">Digital Marketer</div> */}
                          </div>
                        </div>
                      </div>{" "}
                    </div>{" "}
                  </Slider>
                </div>
              </div>
              <div className="custom-arrows-contained">
                <div className="custom-arrows">
                  <div
                    className={`custom-arrow custom-prev ${
                      activeArrow === "prev" ? "active" : ""
                    }`}
                    onClick={handleNext}
                  >
                    {/* {">"} */}
                    <img src={right} alt="Pre" />
                  </div>
                  <div
                    className={`custom-arrow custom-next ${
                      activeArrow === "next" ? "active" : ""
                    }`}
                    onClick={handlePrev}
                  >
                    {/* {"<"} */}
                    <img src={left} alt="Next" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
