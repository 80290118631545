import React, { useEffect } from "react";
import arrow2 from "../../Assets-Dental/arrow-circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const RDProcess = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <>
        <div className="process-flex">
          <div className="process-main-dental">
            <div className="process-main-inside">
              <div
                className="process-first-section"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="dental-flex-hhh">
                  <div className="process-first-section-heading-dental-pro">
                    <h3>Dr Luisa's Approach to Dental Restoration</h3>
                  </div>
                </div>
                <div className="process-para-contained">
                  <div className="process-first-section-content-pro">
                    <p>
                      Dr Luisa uses state-of-the-art technology and materials to
                      ensure the highest quality restorations. With personalised
                      care, from the initial consultation to the final fitting,
                      results are both functional and beautiful.
                    </p>
                    {/* <p style={{ color: "transparent" }} className="p-remove">
        As an experienced Invisalign® provider, Dr Luisa, utilizes a
        combination of advanced 3D imaging technology and a
        personalized treatment plan to ensure that your journey to a
        straighter smile is smooth and efficient.
      </p> */}
                    <p>
                      If you're in need of restorative dental work, or are
                      simply curious about your options, Dr Luisa is here to
                      help. Get in touch to learn more and book your
                      consultation.
                    </p>
                  </div>
                </div>
                <div className="process-first-sectiion-button">
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none" }}
                    onClick={scrollToTop}
                  >
                    <button>
                      Book an appointment <img src={arrow2} alt="arrow" />
                    </button>
                  </Link>
                </div>
              </div>

              <div
                className="process-second-section"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="process-second-section-block-container">
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>1. Fillings</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          The use tooth-coloured materials to seamlessly repair
                          cavities and restore your tooth's integrity.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>2. Crowns</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          Custom-made crowns cover and protect damaged or
                          weakened teeth, restoring their shape and function.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>3. Bridges</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          The design and fit of bridges to close gaps left by
                          missing teeth, improving your bite and smile.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>4. Onlays</h4>
                      </div>
                      <div className="process-second-section-block-content1">
                        <p>
                          These are like a smaller version of a crown, like a
                          hat that sits on top of your tooth.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="process-second-section-block "
                    // style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="process-second-section-block-content-container"
                      //   style={{ backgroundColor: "white" }}
                    >
                      <div className="process-second-section-block-heading">
                        <h4>5. Dentures</h4>
                      </div>
                      <div className="process-second-section-block-content2">
                        <p>
                          Whether partial or full, our dentures are crafted to
                          provide a comfortable fit and a natural look.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default RDProcess;
