import React from "react";
import RestorativeDentistry from "../Restorative-dentistry/RestorativeDentistry";
import RDwhat from "../RestorativeDwhat/RDwhat";
import Teeth from "../Teeth/Teeth";
import RDProcess from "../RestorativeProcess/RDProcess";
import RStory from "../RestorativeStory/RStory";
import ContactTreat from "../Contact-treat/ContactTreat";
import NavbarTreat from "../Navbar-treat/NavbarTreat";
import Footer from "../Footer/Footer";

const RestorativeDmain = () => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <NavbarTreat />
      <RestorativeDentistry />
      <RDwhat />
      <Teeth />
      <RDProcess />
      <RStory />
      <ContactTreat />
      <div style={{ marginTop: "4%" }}></div>
      <Footer />
    </div>
  );
};

export default RestorativeDmain;
