import React, { useState, useEffect, useRef } from "react";
import About from "../About/About";
import Navbar from "../Navbar/Navbar";
import Expert from "../Expert/Expert";
import Career from "../Career/Career";
import Leader from "../Leader/Leader";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import blackbtn from "../../Assets-Dental/Frame 1171275927.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import lframe from "../../Assets-Dental/Frame 1321316174.svg";
import facebook from "../../Assets-Dental/self.png";
import twitter from "../../Assets-Dental/twitter.svg";
import linkdin from "../../Assets-Dental/linkedin 1.svg";
import black from "../../Assets-Dental/black.svg";
import line from "../../Assets-Dental/Line 29.svg";
import { Link } from "react-router-dom";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";
import img from "../../Assets-Dental/Line 10.svg";
import set1 from "../../Assets-Dental/imgggggg.jpeg";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";

const Aboutpage = () => {
  useEffect(() => {
    document.title = "Dr Luisa: The Pink Dentist";
  }, []); //
  useEffect(() => {
    const handleResize = () => {
      const roleSelect = document.getElementById("practice");
      if (roleSelect) {
        roleSelect.options[0].text =
          window.innerWidth < 540
            ? "Choose practice"
            : "Which Practice would you like to be treated at";
      }
    };

    // Initial setup
    handleResize();

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const form = useRef(); // Create a ref for the form element
  const [inputValue, setInputValue] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [inputPhone, setInputPhone] = useState("");
  const [practiceValue, setPracticeValue] = useState(""); // New state for practice selection
  const [messageValue, setMessageValue] = useState(""); // New state for message
  const [showModal, setShowModal] = useState(false);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [practiceError, setPracticeError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [consentError, setConsentError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validateForm = () => {
    let isValid = true;

    // Name validation
    if (!inputValue.trim()) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!inputEmail.trim()) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!emailRegex.test(inputEmail)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Phone validation
    if (!inputPhone.trim()) {
      setPhoneError("Phone number is required");
      isValid = false;
    } else {
      setPhoneError("");
    }

    // Practice validation
    if (!practiceValue) {
      setPracticeError("Practice selection is required");
      isValid = false;
    } else {
      setPracticeError("");
    }

    // Message validation
    if (!messageValue.trim()) {
      setMessageError("Message is required");
      isValid = false;
    } else {
      setMessageError("");
    }

    // Consent validation
    const consentCheckbox = document.getElementById("consentCheckbox");
    if (!consentCheckbox.checked) {
      setConsentError("You must consent to the privacy policy");
      isValid = false;
    } else {
      setConsentError("");
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(
          "https://api.drluisa.co.uk/dentist/send-email",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              subject: "New Enquiry from Dr Luisa Website",
              text: `Name: ${inputValue}\nPhone: ${inputPhone}\nPreferred Practice: ${practiceValue}\nMessage: ${messageValue}\nUserEmail: ${inputEmail}`,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log("Form submitted successfully", data);
          setFormSubmitted(true);
          setInputValue("");
          setInputEmail("");
          setInputPhone("");
          setPracticeValue("");
          setMessageValue("");
          const consentCheckbox = document.getElementById("consentCheckbox");
          if (consentCheckbox) {
            consentCheckbox.checked = false;
          }
        } else {
          setShowModal(true);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setShowModal(true);
      }
    } else {
      setShowModal(true);
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const handleCloseModal = () => {
    setShowModal(false);
    if (formSubmitted) {
      setFormSubmitted(false);
    }
  };
  return (
    <div>
      <div style={{ width: "100%", overflow: "hidden" }}>
        <Navbar />
        <About />
        <Expert />
        <Career />
        <Leader />
        <div style={{ marginTop: "1%" }}></div>
        {/* <Contact /> */}

        <>
          <div>
            <div className="Contact">
              {/* <div className="containt-main-full"> */}
              <div className="Contact-main">
                <div className="Contact-main-inside">
                  <div className="Contact-left-side">
                    <div className="lusia-content lusia-flus">
                      <div className="lusia-img">
                        <img src={img} alt="" />
                      </div>
                      <div className="lusia-heading">
                        <div className="lusia-heading-control control-set-flex">
                          Get In Touch
                        </div>
                      </div>
                    </div>
                    <div className="set-image-set">
                      {" "}
                      <img src={set1} alt="" />
                    </div>

                    <div className="question-lusia">
                      <p>
                        Simply complete the form and one of our team will get in
                        touch to help you get the smile you deserve.
                      </p>
                    </div>
                    <div className="insta-img-set">
                      {" "}
                      <a href="https://www.instagram.com/the.pink.dentist">
                        <img src={facebook} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="Contact-right-section">
                    <form onSubmit={handleSubmit} ref={form}>
                      <div class="form-container">
                        <div className="flex-item">
                          <div className="contact-right-label lable-setting">
                            <label htmlFor="name">Name</label>
                          </div>
                          <div className="contact-right-input input-image-flezzz">
                            <input
                              type="text"
                              id="name"
                              name="user_name"
                              className={`input-image-set ${
                                inputValue && "has-content"
                              }`}
                              value={inputValue}
                              onChange={(e) => setInputValue(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="flex-email-phone-container best-container-contained">
                          <div className="flex-item">
                            <div className="contact-right-label">
                              <label htmlFor="email">Email Address</label>
                            </div>
                            <div className="contact-right-input">
                              <input
                                type="text"
                                id="email"
                                name="email"
                                className={`input-image-set1 ${
                                  inputEmail && "has-content"
                                }`}
                                value={inputEmail}
                                onChange={(e) => setInputEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="flex-item">
                            <div className="contact-right-label phone-label-set">
                              <label htmlFor="name">Phone No.</label>
                            </div>
                            <div className="contact-right-input">
                              <input
                                type="text"
                                id="phone"
                                name="phone"
                                className={`input-image-set2 ${
                                  inputPhone && "has-content"
                                }`}
                                value={inputPhone}
                                onChange={(e) => setInputPhone(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex-item">
                          <div className="contact-right-label ok-label peal-label">
                            <label htmlFor="name">
                              {" "}
                              Which Practice would you like to be treated at
                            </label>
                          </div>
                          <div className="custom-select1">
                            <select
                              id="practice"
                              name="practice"
                              value={practiceValue}
                              onChange={(e) => setPracticeValue(e.target.value)}
                              defaultValue=""
                              className="ddd"
                            >
                              <option value="" disabled hidden>
                                {" "}
                              </option>
                              <option value="All Saints Dental">
                                All Saints Dental
                              </option>
                              <option value="{my}dentist Kings Norton">
                                {"{my}"}dentist Kings Norton
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="flex-item">
                          <div className="contact-right-label msg-label">
                            <label htmlFor="message">Message</label>
                          </div>

                          <textarea
                            id="message"
                            name="message"
                            value={messageValue}
                            onChange={(e) => setMessageValue(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                      <div class="form-container">
                        <div className="para-form2">
                          <div className="para-form-input">
                            <div className="input-checked">
                              <input
                                type="checkbox"
                                id="consentCheckbox"
                                className="custom-checkbox"
                              />
                            </div>
                            <div className="para-form2-content">
                              <p>
                                I consent to my personal data being collected
                                and stored as per the{" "}
                                <Link
                                  to="/privacypage"
                                  style={{ textDecoration: "none" }}
                                  onClick={scrollToTop}
                                >
                                  <span style={{ color: "#D521A0" }}>
                                    Privacy Policy.
                                  </span>{" "}
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="form-button">
                          <button type="submit">
                            Submit Form <img src={arrow} alt="" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          <Modal
            show={showModal || formSubmitted}
            onHide={() => setShowModal(false)}
            onClick={handleCloseModal}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {formSubmitted ? "Form submitted" : "Validation Error"}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {formSubmitted ? (
                <div className="success">Form submitted successfully</div>
              ) : (
                <>
                  {nameError && <div className="error">{nameError}</div>}
                  {emailError && <div className="error">{emailError}</div>}
                  {phoneError && <div className="error">{phoneError}</div>}
                  {practiceError && (
                    <div className="error">{practiceError}</div>
                  )}
                  {messageError && <div className="error">{messageError}</div>}
                  {consentError && <div className="error">{consentError}</div>}
                </>
              )}
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        <div style={{ marginBottom: "3%" }}></div>
        <Footer />
      </div>
    </div>
  );
};

export default Aboutpage;
