import React, { useEffect } from "react";
import Line from "../../Assets-Dental/Line 10.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const BondingOral = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <div className="lusia-conatiner-offer">
        <div className="lusia-container-full-contained-offer">
          <div className="lusia-left-offer1">
            <div
              className="lusia-contained-offer"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    What is Composite Bonding?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight">
                <p>
                  Composite bonding involves the application of a tooth-coloured
                  resin material to reshape, rebuild, or whiten teeth. It is
                  meticulously moulded and hardened with a special light, then
                  polished to blend seamlessly with the surrounding tooth
                  structure.
                </p>
              </div>
              <div className="set-tive"></div>
              <div className="about-button">
                <Link to="/contact">
                  <button onClick={scrollToTop}>
                    Get In Touch
                    <img src={arrow} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="lusia-right-offer1"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="700"
          >
            <div
              className="lusia-contained-offer res-bes"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    Why Choose Composite Bonding?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight">
                <p>
                  Composite bonding is a popular choice for those looking to
                  make cosmetic improvements without the need for more invasive
                  procedures. It is ideal for repairing chips, closing gaps,
                  reshaping, or improving the colour of teeth. Depending on the
                  complexity, it can be done in just one visit.
                </p>
              </div>
              {/* <div className="about-button">
      <button>
  
      </button>
    </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BondingOral;
