import React, { useEffect } from "react";
import Line from "../../Assets-Dental/Line 10.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll, Events, scrollSpy } from "react-scroll";

const Oral = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div className="lusia-conatiner-offer">
      <div className="lusia-container-full-contained-offer">
        <div className="lusia-left-offer1">
          <div
            className="lusia-contained-offer"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-duration="600"
          >
            <div className="expert-content-oral">
              <div className="expert-img-oral">
                <img src={Line} alt="" />
                <div className="expert-heading-control-offer-oral">
                  What is General Dentistry?
                </div>
              </div>
            </div>
            <div className="lusia-para-offer-oral">
              <p>
                General dentistry encompasses a variety of dental treatments
                aimed at helping you maintain healthy teeth and gums. It’s the
                foundation of dental care, ensuring that your smile stays in
                excellent condition and functions at its best.
              </p>
            </div>
            <div className="set-tive"></div>
            <div className="about-button">
              <Link to="/contact">
                <button onClick={scrollToTop}>
                  Get In Touch
                  <img src={arrow} alt="" />
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="lusia-right-offer1"
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-duration="700"
        >
          <div
            className="lusia-contained-offer res-bes"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="600"
          >
            <div className="expert-content-oral">
              <div className="expert-img-oral">
                <img src={Line} alt="" />
                <div className="expert-heading-control-offer-oral">
                  Why General Dentistry Matters?
                </div>
              </div>
            </div>
            <div className="lusia-para-offer-oral">
              <p>
                Regular dental visits are crucial for the early detection and
                prevention of oral health issues. By addressing small problems
                before they escalate, it saves you time and discomfort, and
                helps you avoid the need for more complex treatments.
              </p>
            </div>
            {/* <div className="about-button">
              <button>
           
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Oral;
