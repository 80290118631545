import React, { useEffect } from "react";

import "./Career.css";
import "./responsive.css";
import injection from "../../Assets-Dental/career.png";
import Line from "../../Assets-Dental/Line 10.svg";
import AOS from "aos";
import "aos/dist/aos.css";
const Career = () => {
  return (
    <div className="expert-content-container-full-responsive">
      <div className="expert-content-container-full">
        <div className="expert-container">
          <div className="expert-container-contained1">
            <div
              className="expert-right1"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-right-content-container1">
                {" "}
                <div className="expert-content-container">
                  <div className="expert-content1">
                    <div className="expert-img1">
                      <img src={Line} alt="" />
                      <div className="expert-heading-control1">
                        Dr Luisa is here for you
                      </div>
                    </div>
                  </div>
                </div>
                <div className="expert-heading-container1">
                  <p>
                    {" "}
                    Luisa has completed a number of orthodontic postgraduate
                    courses as well being an active part of multiple online
                    professional groups and dental forums.{" "}
                  </p>
                </div>
                <div className="expert-para-container1">
                  <p>
                    Luisa’s main interest lies within cosmetic composite
                    build-ups and the use of clear aligners such as Invisalign
                    but also more complex restorative cases.{" "}
                    <span style={{ color: "white" }} className="spannnnn-fit">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Eius placeat beatae veritatis ut aliquam perspiciatis!
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sunt, at. Lorem ipsum, dolor sit amet consectetur
                      adipisicing elit. Eius placeat beatae Lorem ipsum, dolor
                      sit amet consectetur adipisicing elit. Eius placeat beatae
                      veritatis ut aliquam perspiciatis! Lorem ipsum dolor sit
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="expert-left1"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-left-contained">
                <img src={injection} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
