import React, { useEffect } from "react";
import arrow2 from "../../Assets-Dental/arrow-circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const ProcelainProcess = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <>
        <div className="process-flex">
          <div className="process-main-dental">
            <div className="process-main-inside">
              <div
                className="process-first-section"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="dental-flex-hhh">
                  <div className="process-first-section-heading-dental-pro">
                    <h3>Dr Luisa's Approach to Your Smile Makeover</h3>
                  </div>
                </div>
                <div className="process-para-contained">
                  <div className="process-first-section-content-pro">
                    <p>
                      Dr Luisa combines the science of dentistry with an
                      artistic eye to design veneers that complement your
                      features and personal aesthetic. Using advanced
                      techniques, we ensure a seamless fit and an impeccable
                      look.
                    </p>
                    {/* <p style={{ color: "transparent" }} className="p-remove">
        As an experienced Invisalign® provider, Dr Luisa, utilizes a
        combination of advanced 3D imaging technology and a
        personalized treatment plan to ensure that your journey to a
        straighter smile is smooth and efficient.
      </p> */}
                    <p>
                      If you’re considering porcelain veneers or would like to
                      learn more about how they can revolutionise your smile, Dr
                      Luisa can help. Get in touch to schedule your
                      consultation.
                    </p>
                  </div>
                </div>
                <div className="process-first-sectiion-button">
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none" }}
                    onClick={scrollToTop}
                  >
                    <button>
                      Book an appointment <img src={arrow2} alt="arrow" />
                    </button>
                  </Link>
                </div>
              </div>

              <div
                className="process-second-section"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="process-second-section-block-container">
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>1. Consultation</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          It starts with a conversation about your aspirations
                          for your smile and a comprehensive evaluation of your
                          dental health.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>2. Design</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          Dr Luisa will scan your teeth and work with you to
                          choose the right shade and shape for your veneers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>3. Preparation</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          A minimal amount of tooth enamel is gently shaped to
                          accommodate the veneers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>4. Fitting</h4>
                      </div>
                      <div className="process-second-section-block-content1">
                        <p>
                          Once crafted, your veneers are carefully bonded to
                          your teeth, instantly enhancing your smile.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="process-second-section-block "
                    // style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="process-second-section-block-content-container"
                      //   style={{ backgroundColor: "white" }}
                    >
                      <div className="process-second-section-block-heading">
                        <h4>5. Reveal</h4>
                      </div>
                      <div className="process-second-section-block-content2">
                        <p>
                          The final step is the most rewarding—unveiling your
                          transformed, radiant smile.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ProcelainProcess;
