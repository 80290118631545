import React from "react";
import NavbarTreat from "../Navbar-treat/NavbarTreat";
import ContactTreat from "../Contact-treat/ContactTreat";
import Footer from "../Footer/Footer";
import ProcessInvis from "../Prolcelain-invis/ProcessInvis";
import ProcelainOral from "../Procelain-Oral/ProcelainOral";
import ProcelainProcess from "../Procelain-process/ProcelainProcess";
import ProcelainStory from "../procelain-story/ProcelainStory";
import Teeth from "../Teeth/Teeth";

const Procelain = () => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <NavbarTreat />
      <ProcessInvis />
      <ProcelainOral />
      <Teeth />
      <ProcelainProcess />
      <ProcelainStory />
      <ContactTreat />
      <div style={{ marginTop: "4%" }}></div>
      <Footer />
    </div>
  );
};

export default Procelain;
