import React, { useEffect } from "react";
import "../Process/Process.css";
import "./responsive.css";
import arrow2 from "../../Assets-Dental/arrow-circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Process() {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  return (
    <>
      <div className="process-flex">
        <div className="process-main">
          <div className="process-main-inside">
            <div
              className="process-first-section"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="process-first-section-heading">
                <h3>The Invisalign Process</h3>
              </div>
              <div className="process-para-contained">
                <div className="process-first-section-content">
                  <p>
                    Invisalign is an innovative orthodontic system that uses
                    custom-made, clear plastic aligners to gradually straighten
                    teeth.
                    <br />
                    It's an ideal solution for people looking to improve their
                    smile without the appearance of traditional metal braces.
                  </p>
                  <p>
                    As an experienced Invisalign® provider, Dr Luisa, utilizes a
                    combination of advanced 3D imaging technology and a
                    personalized treatment plan to ensure that your journey to a
                    straighter smile is smooth and efficient.
                  </p>
                </div>
              </div>
              <div className="process-first-sectiion-button">
                <button>
                  Book an appointment <img src={arrow2} alt="arrow" />
                </button>
              </div>
            </div>

            <div
              className="process-second-section"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="process-second-section-block-container">
                <div className="process-second-section-block">
                  <div className="process-second-section-block-content-container">
                    <div className="process-second-section-block-heading">
                      <h4>1. Assessment</h4>
                    </div>
                    <div className="process-second-section-block-content">
                      <p>
                        A detailed examination and digital scans form the basis
                        for your custom aligners.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-second-section-block">
                  <div className="process-second-section-block-content-container">
                    <div className="process-second-section-block-heading">
                      <h4>2. Custom Aligners</h4>
                    </div>
                    <div className="process-second-section-block-content">
                      <p>
                        Using Invisalign's proprietary software, a series of
                        aligners are designed that will move your teeth.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-second-section-block">
                  <div className="process-second-section-block-content-container">
                    <div className="process-second-section-block-heading">
                      <h4>3. Progress Monitoring</h4>
                    </div>
                    <div className="process-second-section-block-content">
                      <p>
                        With regular check-ups, your progress is monitored as
                        the treatment progresses.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-second-section-block">
                  <div className="process-second-section-block-content-container">
                    <div className="process-second-section-block-heading">
                      <h4>4. Final Adjustments</h4>
                    </div>
                    <div className="process-second-section-block-content1">
                      <p>
                        Once your teeth have reached their desired positions, a
                        final set of aligners are created to ensure a perfect
                        finish.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="process-second-section-block">
                  <div className="process-second-section-block-content-container">
                    <div className="process-second-section-block-heading">
                      <h4>5. Retention</h4>
                    </div>
                    <div className="process-second-section-block-content2">
                      <p>
                        You'll get fitted with a retainer to maintain your new
                        smile and prevent future shifting.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
