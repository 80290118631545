import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Treat from "../Treat/Treat";
import Dental from "../Dental/Dental";
import Lusia from "../Lusia/Lusia";
import Treatments from "../Treatments/Treatments";
import Contact from "../Contact/Contact";
import Smile from "../Smile/Smile";
import Testimonials from "../Testimonials/Testimonials";
import Footer from "../Footer/Footer";

const Page = () => {
  useEffect(() => {
    document.title = "Dr Luisa: The Pink Dentist";
  }, []); //
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Navbar />
      <Treat />
      <Lusia />
      <Dental />
      <Treatments />
      <Smile />
      <Contact />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default Page;
