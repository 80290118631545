import React, { useEffect } from "react";
import Line from "../../Assets-Dental/Line 10.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
const RootCWhat = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <div className="lusia-conatiner-offer">
        <div className="lusia-container-full-contained-offer">
          <div className="lusia-left-offer1">
            <div
              className="lusia-contained-offer connnnnnn"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    What is a Root Canal Treatment?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight root-canal-heading">
                <p>
                  A root canal treatment is aimed at treating infection at the
                  centre of a tooth. It's not the treatment that's painful; it's
                  the infection. The goal is to alleviate pain, remove the
                  infection, and ultimately, save your tooth.{" "}
                </p>
              </div>
              <div className="set-tive"></div>
              <div className="about-button">
                <Link to="/contact">
                  <button onClick={scrollToTop}>
                    Get In Touch
                    <img src={arrow} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="lusia-right-offer1"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="700"
          >
            <div
              className="lusia-contained-offer"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    Why Might You Need a Root Canal?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight">
                <p>
                  If the pulp inside your tooth, made up of soft tissue
                  including nerves and blood vessels, becomes infected through
                  decay or injury, you may need a root canal treatment. Without
                  treatment, the infection can spread and may lead to an
                  abscess, causing significant discomfort and potentially
                  requiring an extraction.
                </p>
              </div>
              {/* <div className="about-button">
  <button>

  </button>
</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RootCWhat;
