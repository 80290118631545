import React, { useEffect } from "react";
import Touch from "../Touch/Touch";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Church from "../Church/Church";
import Testimonials from "../Testimonials/Testimonials";

const TouchPage = () => {
  useEffect(() => {
    document.title = "Dr Luisa: The Pink Dentist";
  }, []); //
  return (
    <div style={{ overflow: "hidden", width: "100%" }}>
      <Navbar />
      <Touch />
      <div style={{ marginTop: "5%" }}></div>
      <Church />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default TouchPage;
