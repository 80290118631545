import React from "react";
import NavbarTreat from "../Navbar-treat/NavbarTreat";
import ContactTreat from "../Contact-treat/ContactTreat";
import Footer from "../Footer/Footer";
import BondingInvis from "../Bonding-invis/BondingInvis";
import BondingOral from "../Bonding-Oral/BondingOral";
import BondingProcess from "../Bonding-process/BondingProcess";
import BondingStory from "../Bonding-story/BondingStory";
import Teeth from "../Teeth/Teeth";

const BondingPage = () => {
  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <NavbarTreat />
      <BondingInvis />
      <BondingOral />
      <Teeth />
      <BondingProcess />
      <BondingStory />
      <ContactTreat />
      <div style={{ marginTop: "4%" }}></div>
      <Footer />
    </div>
  );
};

export default BondingPage;
