import React, { useEffect } from "react";
import "./Story.css";
import "./responsive.css";
import injection from "../../Assets-Dental/story-image.svg";
import Line from "../../Assets-Dental/Line 10.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import Img1 from "../../Assets-Dental/story-img2.svg";
import Img2 from "../../Assets-Dental/story-img1.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Story = () => {
  return (
    <div>
      {" "}
      <div className="expert-content-container-full-responsive">
        <div className="expert-content-container-full">
          <div className="expert-container-story">
            <div className="expert-container-contained1-story">
              <div
                className="expert-right-story"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="expert-right-content-container1">
                  {" "}
                  <div className="expert-content-container">
                    <div className="expert-content1">
                      <div className="expert-img1">
                        <img src={Line} alt="" />
                        <div className="expert-heading-control1">Our story</div>
                      </div>
                    </div>
                  </div>
                  <div className="expert-heading-container-story">
                    <p>Dr Luisa's Approach to Straightening Smiles</p>
                  </div>
                  <div className="expert-para-container-story">
                    <p>
                      As an experienced Invisalign® provider, Dr Luisa, utilises
                      a combination of advanced 3D imaging technology and a
                      personalised treatment plan to ensure that your journey to
                      a straighter smile is smooth and efficient.
                    </p>
                  </div>
                  <div className="expert-heading-container-story">
                    <p>Aftercare and Support</p>
                  </div>
                  <div className="expert-para-container-story1">
                    <p>
                      Dr Luisa is dedicated to providing ongoing support
                      throughout your Invisalign® treatment, ensuring you're
                      confident and comfortable at every stage as well as making
                      sure your teeth remain in place.
                    </p>
                  </div>
                  <div className="about-button10">
                    <Link>
                      <button>
                        Get In Touch
                        <img src={arrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="expert-left-story"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="expert-left-contained-story">
                  <img src={injection} alt="" />
                  <div className="story-content-img-flex">
                    <div className="story-mg-flex">
                      <div className="expert-img-container-contained">
                        <img src={Img1} alt="" />
                      </div>
                      <div className="expert-img-container-contained1">
                        <img src={Img2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
