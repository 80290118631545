import React, { useEffect } from "react";
import arrow2 from "../../Assets-Dental/arrow-circle.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const EnlightProcess = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <>
        <div className="process-flex">
          <div className="process-main-dental">
            <div className="process-main-inside">
              <div
                className="process-first-section"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="dental-flex-hhh">
                  <div className="process-first-section-heading-dental-white">
                    <h3>Dr Luisa's Approach to Your Brighter Smile</h3>
                  </div>
                </div>
                <div className="process-para-contained">
                  <div className="process-first-section-content">
                    <p>
                      Your comfort and stunning results are our priority. We use
                      3D scanners and high-quality whitening gel from one of the
                      leading providers to deliver to you with a safe and
                      pleasant experience to get your whiter smile.
                    </p>
                    {/* <p style={{ color: "transparent" }} className="p-remove">
                As an experienced Invisalign® provider, Dr Luisa, utilizes a
                combination of advanced 3D imaging technology and a
                personalized treatment plan to ensure that your journey to a
                straighter smile is smooth and efficient.
              </p> */}
                    <p>
                      If you’re ready to enhance your smile with professional
                      teeth whitening, contact us to book a consultation, and
                      take the first step towards a brighter, more confident
                      smile.
                    </p>
                  </div>
                </div>
                <div className="process-first-sectiion-button">
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none" }}
                    onClick={scrollToTop}
                  >
                    <button>
                      Book an appointment <img src={arrow2} alt="arrow" />
                    </button>
                  </Link>
                </div>
              </div>

              <div
                className="process-second-section"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="process-second-section-block-container">
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>1. Consultation</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          Every whitening journey begins with a thorough
                          assessment of your dental health and discussion of
                          your aesthetic goals.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>2. Customisation</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          A scan of your teeth is taken to create custom
                          whitening trays that fit perfectly, ensuring even
                          coverage and comfort.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>3. Whitening</h4>
                      </div>
                      <div className="process-second-section-block-content">
                        <p>
                          Depending on your needs and preferences, whitening
                          syringes will be prescribed for at-home whitening.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="process-second-section-block">
                    <div className="process-second-section-block-content-container">
                      <div className="process-second-section-block-heading">
                        <h4>4. Final Touches</h4>
                      </div>
                      <div className="process-second-section-block-content1">
                        <p>
                          Post-whitening, Dr Luisa will provide you with expert
                          advice to maintain your new, brighter smile and ensure
                          long-lasting results.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="process-second-section-block process-section-none"
                    style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="process-second-section-block-content-container"
                      style={{ backgroundColor: "white" }}
                    >
                      <div className="process-second-section-block-heading">
                        <h4 style={{ color: "white" }}>5. Family Dentistry</h4>
                      </div>
                      <div className="process-second-section-block-content2">
                        <p style={{ color: "white" }}>
                          Dr Luisa offers treatments for patients of all ages,
                          focusing on education and prevention for the youngest
                          visitors.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EnlightProcess;
