import React from "react";
import "../Treatments/Treatments.css";
import Img1 from "../../Assets-Dental/ring.png";
import Img2 from "../../Assets-Dental/pngkey 1.svg";
import Img3 from "../../Assets-Dental/treatment1.png";
import Img4 from "../../Assets-Dental/treatment2.png";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

export default function Treatments() {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <div className="Treatments-main ">
        <div className="Treatments">
          <div className="Treatments-second-container">
            <div className="Treatments-content">
              <div className="Treatments-heading1">
                <p>Treatments</p>
              </div>
              <div className="Treatments-heading2">
                <h1>Your smile starts with</h1>
              </div>
            </div>
            <div className="treatments-container-contained">
              <div className="Treatments-grid-container">
                <Link
                  to="/invisalign"
                  style={{ textDecoration: "none", color: "black" }}
                  onClick={scrollToTop}
                  className="Treatments-grid"
                >
                  {/* <div className="Treatments-grid"> */}
                  {/* <div className="Treatment-grid-imge"> */}
                  <div className="treatment-box-grid">
                    <div className="img-contained-treatment">
                      <img src={Img1} alt="" />
                    </div>
                    <div className="treeeet-flex">
                      <div className="treatment-flex-content-contained">
                        <div className="treatment-heading-flex">
                          <h2>Invisalign</h2>
                        </div>
                        {/* </div> */}
                        <div className="treatment-para-flex">
                          <p>
                            Straighten your teeth discreetly with clear
                            aligners.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </Link>
                <Link
                  to="/bonding"
                  style={{ textDecoration: "none", color: "black" }}
                  onClick={scrollToTop}
                  className="Treatments-grid"
                >
                  {/* <div className="Treatments-grid"> */}
                  {/* <div className="Treatment-grid-imge"> */}
                  <div className="treatment-box-grid1">
                    <div className="img-contained-treatment">
                      <img src={Img2} alt="" />
                    </div>
                    <div className="treeeet-flex">
                      <div className="treatment-flex-content-contained">
                        <div className="treatment-heading-flex">
                          <h2>Composite bonding</h2>
                        </div>
                        {/* </div> */}
                        <div className="treatment-para-flex">
                          <p>
                            Give your teeth in the shape and size that you want.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </Link>
                <Link
                  to="/englightent"
                  style={{ textDecoration: "none", color: "black" }}
                  onClick={scrollToTop}
                  className="Treatments-grid"
                >
                  {/* <div className="Treatments-grid"> */}
                  {/* <div className="Treatment-grid-imge"> */}
                  <div className="treatment-box-grid2">
                    <div className="treat-grid-img-flex ">
                      <img src={Img3} alt="" />
                    </div>
                    <div className="treeeet-flex1">
                      <div className="treatment-flex-content-contained1">
                        <div className="treatment-heading-flex">
                          <h2>Enlighten</h2>
                        </div>
                        {/* </div> */}
                        <div className="treatment-para-flex flex-det">
                          <p> Guaranteed to help brighten your smile.</p>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </Link>
                <Link
                  to="/procelain"
                  style={{ textDecoration: "none", color: "black" }}
                  onClick={scrollToTop}
                  className="Treatments-grid"
                >
                  {/* <div className="Treatments-grid"> */}
                  {/* <div className="Treatment-grid-imge"> */}
                  <div className="treatment-box-grid3">
                    <div className="treat-grid-img-flex">
                      <img src={Img4} alt="" />
                    </div>
                    <div className="treeeet-flex1">
                      <div className="treatment-flex-content-contained1">
                        <div className="treatment-heading-flex">
                          <h2>Veneers </h2>
                        </div>
                        {/* </div> */}
                        <div className="treatment-para-flex flex-det1">
                          <p>
                            Finger nail thin porcelain to help correct the look
                            of your teeth.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* </div> */}
                </Link>
              </div>
            </div>
            <div className="Treatments-button">
              <button>Learn more</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
