import React, { useEffect } from "react";
import "./Invis.css";
import "./resposnive.css";
import arrow from "../../Assets-Dental/arrow.svg";
import Img from "../../Assets-Dental/invis-removebg-preview.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Invis = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  return (
    <div>
      {" "}
      <div className="about-flex-container1">
        <div className="about-full-conatiner-contained">
          <div className="about-container-contained">
            <div
              className="about-container-left"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="about-container-contained-leftt">
                <div className="about-heading-invis">
                  <h2>
                    Invisalign Treatment By
                    <span style={{ color: "#D521A0" }}> Dr Lusia</span>{" "}
                  </h2>
                </div>
                <div className="about-para-invis">
                  <p>
                    Dr Luisa is proud to offer her patients Invisalign® - the
                    clear alternative to braces that has revolutionised
                    orthodontic treatment. With Invisalign®, achieving a
                    perfectly aligned smile is easier and more discreet than
                    ever.
                  </p>
                  <div className="about-button">
                    <Link to="/contact">
                      <button>
                        Get In Touch
                        <img src={arrow} alt="" />
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="about-container-right"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="about-right-img10">
                <img src={Img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invis;
