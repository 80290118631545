import React, { useEffect } from "react";
import "./Treat.css";
import img from "../../Assets-Dental/bk-line.svg";
import she from "../../Assets-Dental/cropppp-removebg-preview.png";
import frame from "../../Assets-Dental/Frame.svg";
import icon from "../../Assets-Dental/BG.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const Treat = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <div className="treat-container treatcontainer-merging">
        <div className="treat-img">
          <img src={img} alt="" />
        </div>
        <div className="trest-full-container">
          <div className="treat-left">
            <div
              className="left-heading"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <h2 className="heading-control">
                Treat yourself to a beautiful,
                <span style={{ color: "#D521A0" }}> new </span> smile
              </h2>

              <div className="treat-para">
                <p style={{ color: "white" }}>
                  Excepteur sint occaecat cupidatat non proident,
                </p>
              </div>
              <div className="left-button">
                <Link to="/contact">
                  <button onClick={scrollToTop}>Get In Touch</button>
                </Link>
              </div>
            </div>
            <div
              className="left-img"
              data-aos="zoom-in"
              data-aos-duration="800"
            >
              <img src={she} alt="" />
            </div>
          </div>
          <div className="treat-right">
            <div
              className="right-content-container"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="right-heading">
                <h2>LOCATIONS</h2>
              </div>
              <div className="right-icon-container">
                <div className="re">
                  <img src={frame} alt="" />
                </div>
                <div className="le">
                  <div className="le-heading">
                    <span style={{ fontWeight: "700" }}>All Saints Dental</span>
                    , 2D Vicarage Rd, King's Heath, Birmingham B14 7RA
                  </div>
                </div>
              </div>
              <div className="right-icon-container">
                <div className="re">
                  <img src={frame} alt="" />
                </div>
                <div className="le1">
                  <div className="le-heading">
                    <span style={{ fontWeight: "700" }}>
                      {" "}
                      {"{my}"}dentist Kings Norton
                    </span>
                    , 183 Redditch Rd, Kings Norton, Birmingham B38 8RH
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="treat-container-responsive treatcontainer-merging-ok">
        <div className="image-container-responsive">
          <div className="treat-img1">
            <img src={img} alt="" />
          </div>
          <div className="left-img1" data-aos="zoom-in" data-aos-duration="800">
            <img src={she} alt="" />
          </div>
        </div>
        <div className="responsive-content-container ">
          <div className="responive-left">
            <div className="left-responsive-content-contained">
              <div
                className="left-heading1"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <h2 className="heading-control1">
                  Treat yourself to a beautiful,
                  <span style={{ color: "#D521A0" }}> new </span> smile
                </h2>

                <div className="treat-para1">
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </div>
                <div className="left-button1">
                  <button>Get In Touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className="responive-right">
            <div className="treat-right-responisve">
              <div
                className="right-content-container1"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-duration="600"
              >
                <div className="right-heading1">
                  <h2>LOCATIONS</h2>
                </div>
                <div className="right-icon-container1">
                  <div className="re1">
                    <img src={frame} alt="" />
                  </div>
                  <div className="le2">
                    <div className="le-heading1">
                      <span style={{ fontWeight: "700" }}>
                        All Saints Dental
                      </span>
                      , 2D Vicarage Rd, King's Heath, Birmingham B14 7RA
                    </div>
                  </div>
                </div>
                <div className="right-icon-container1">
                  <div className="re1">
                    <img src={frame} alt="" />
                  </div>
                  <div className="le3">
                    <div className="le-heading1">
                      <span style={{ fontWeight: "700" }}>
                        {" "}
                        {"{my}"}dentist Kings Norton
                      </span>
                      , 183 Redditch Rd, Kings Norton, Birmingham B38 8RH
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Treat;
