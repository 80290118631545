import React, { useEffect } from "react";
import Line from "../../Assets-Dental/Line 10.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const RDwhat = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <div className="lusia-conatiner-offer">
        <div className="lusia-container-full-contained-offer">
          <div className="lusia-left-offer1">
            <div
              className="lusia-contained-offer"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    What is Restorative Dentistry?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight">
                <p>
                  Restorative dentistry encompasses various dental treatments
                  designed to repair or replace teeth. This includes fillings,
                  crowns, bridges and dentures, which all are designed to
                  restore both the function and appearance of your teeth.
                </p>
              </div>
              <div className="set-tive"></div>
              <div className="about-button">
                <Link to="/contact">
                  <button onClick={scrollToTop}>
                    Get In Touch
                    <img src={arrow} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="lusia-right-offer1"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="700"
          >
            <div
              className="lusia-contained-offer"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight restorative-head-set">
                    Why Restorative Dentistry is Essential?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight">
                <p>
                  Maintaining the integrity of your mouth is crucial not just
                  for aesthetics, but also for your overall health. Restorative
                  procedures help to prevent further decay, support proper
                  chewing and speaking, and maintain the structure of your jaw
                  and face.
                </p>
              </div>
              {/* <div className="about-button">
  <button>

  </button>
</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RDwhat;
