import React, { useEffect } from "react";
import Line from "../../Assets-Dental/Line 10.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

const ProcelainOral = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div>
      {" "}
      <div className="lusia-conatiner-offer">
        <div className="lusia-container-full-contained-offer">
          <div className="lusia-left-offer1">
            <div
              className="lusia-contained-offer"
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    What are Porcelain Veneers?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight procelien-heading-setting">
                <p>
                  Porcelain veneers are bespoke, wafer-thin shells crafted from
                  high-quality ceramic that are bonded to the front surface of
                  your teeth. They are renowned for their ability to correct
                  discolouration, chips, gaps, and misalignments with a natural,
                  durable finish.
                </p>
              </div>
              <div className="set-tive"></div>
              <div className="about-button">
                <Link to="/contact">
                  <button onClick={scrollToTop}>
                    Get In Touch
                    <img src={arrow} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="lusia-right-offer1"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="700"
          >
            <div
              className="lusia-contained-offer"
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-duration="600"
            >
              <div className="expert-content-oral">
                <div className="expert-img-oral">
                  <img src={Line} alt="" />
                  <div className="expert-heading-control-offer-oral-enlight">
                    Why Choose Porcelain Veneers?
                  </div>
                </div>
              </div>
              <div className="lusia-para-offer-oral-enlight procelian-enligh-setting">
                <p>
                  Opting for porcelain veneers means choosing a long-term
                  solution that improves the appearance of your smile while
                  preserving the natural structure of your teeth. Whilst it
                  could involve some tooth preperation, they are custom-made for
                  colour, shape, and size, ensuring a natural look and feel.
                </p>
              </div>
              {/* <div className="about-button">
  <button>

  </button>
</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcelainOral;
