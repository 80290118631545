import React, { useEffect } from "react";
import "./Church.css";
import "./responsive.css";
import logo from "../../Assets-Dental/real-logo-ori.png";
import frame from "../../Assets-Dental/location-sign-svgrepo-com.svg";
import phone from "../../Assets-Dental/phone-svgrepo-com.svg";
import msg from "../../Assets-Dental/message-alt-svgrepo-com.svg";
import clock from "../../Assets-Dental/clock-0500-svgrepo-com.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Church = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  return (
    <div className="church-container-church">
      <div className="church-container">
        <div className="church-main-heading">
          <h2>Where You Can Find Dr Luisa </h2>
        </div>
        <div className="church-cpntainer-contained-material">
          <div
            className="church-left"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-duration="600"
          >
            <div className="church-left-content church-content-control">
              <div className="church-img">
                <img src={logo} alt="" />
              </div>
              <div className="church-heading-container church-heading-container-uffff-hmm">
                <div className="church-heading">
                  <h2>{"{my}"}dentist Kings Norton</h2>
                </div>
                <div className="img-heading-container">
                  <h1>
                    <img src={frame} alt="" className="phone-setttt" />
                    183 Redditch Rd, Kings Norton, Birmingham B38 8RH
                  </h1>
                </div>
                <div className="img-heading-container">
                  <h1>
                    <img src={phone} alt="" className="phone-set" />
                    01527 64727
                  </h1>
                </div>{" "}
                <div className="img-heading-container">
                  <h1>
                    <img src={msg} alt="" className="phone-set" />
                    hello@drluisa.co.uk
                  </h1>
                </div>{" "}
                <div className="img-heading-container">
                  <h1>
                    <img src={clock} alt="" className="phone-set" />
                    Mon - Fri: 9:00 AM - 5:00 PM
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div
            className="church-right"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="600"
          >
            <div className="church-left-content">
              <div className="church-img">
                <img src={logo} alt="" />
              </div>
              <div className="church-heading-container church-heading-container-uffff">
                <div className="church-heading">
                  <h2>All Saints Dental Clinic</h2>
                </div>
                <div className="img-heading-container">
                  <h1>
                    <img src={frame} alt="" className="phone-setttt" />
                    2D Vicarage Rd, King's Heath, Birmingham B14 7RA
                  </h1>
                </div>
                <div className="img-heading-container">
                  <h1>
                    <img src={phone} alt="" className="phone-set" />
                    0121 444.0496
                  </h1>
                </div>{" "}
                <div className="img-heading-container">
                  <h1>
                    <img src={msg} alt="" className="phone-set" />
                    hello@drluisa.co.uk
                  </h1>
                </div>{" "}
                <div className="img-heading-container">
                  <h1>
                    <img src={clock} alt="" className="phone-set" />
                    Mon - Fri: 9:00 AM - 5:00 PM
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Church;
