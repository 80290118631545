import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import "./Navbar.css";
import "./responsive.css";
// import Logo from "../../Assets-Dental/real-logo-ori.png";
import Logo from "../../Assets-Dental/real-logo-ori(copy).png";
import PhoneSvg from "../../Assets-Dental/Group 1171276954.svg";
import Vector from "../../Assets-Dental/Vector (1).svg";
import Icon from "../Icon/Icon";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleDropdownClick = (dropdownItem) => {
    setActiveDropdown((prevActive) =>
      prevActive === dropdownItem ? null : dropdownItem
    );
  };

  return (
    <>
      <div className="navbar-contained">
        <nav className="navbar navbar-expand-lg container10">
          <div className="container-fluid" id="cont-fld">
            <NavLink className="navbar-brand" to="/">
              {/* <div className="img0set-container-full"> */}
              <img
                src={Logo}
                alt="Logo"
                style={{
                  width: "120px",
                  height: "120px",
                  objectFit: "contain",
                }}
                className="logo-img"
              />
              {/* </div> */}
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
              style={{
                justifyContent: "space-between",
              }}
            >
              <ul
                className="navbar-nav mb-2 mb-lg-0 nav-control-ul"
                style={{ gap: "36px" }}
              >
                <li className="nav-item ">
                  <NavLink className="nav-link" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/about">
                    About me
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className=" dropdown-toggle treat-move "
                    role="button"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    onClick={() => handleDropdownClick("treatments")}
                  >
                    Treatments{" "}
                    <Icon
                      isActive={activeDropdown === "treatments"}
                      isTreatments
                    />
                  </NavLink>

                  <ul className="dropdown-menu">
                    <li>
                      <NavLink className="dropdown-item" to="/generaldensity">
                        General Dentistry
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/englightent">
                        Enlighten Teeth Whitening
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/bonding">
                        Composite Bonding
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/procelain">
                        Procelain Veneers
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/restorative">
                        Restorative Dentistry
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/root">
                        Root Canal Treatments
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/invisalign">
                        Invisalign
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink className="dropdown-item" to="/facial">
                        Facial Aesthetics
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/testimonials">
                    Testimonials
                  </NavLink>
                </li>
              </ul>
              <ul
                className="navbar-nav mb-2 mb-lg-0"
                style={{
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <div className="navbar-button">
                  <Link to="/contact">
                    <button>Get in Touch</button>
                  </Link>
                </div>

                <li className="nav-item">
                  {" "}
                  <a href="tel://01527 64727">
                    <img
                      src={PhoneSvg}
                      alt="PhoneSvg"
                      style={{ width: "54px" }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
export default Navbar;
