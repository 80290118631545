import React, { useEffect } from "react";
import "./Offer.css";
import "./responsive.css";
import Line from "../../Assets-Dental/Line 10.svg";
import arrow from "../../Assets-Dental/arrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Offer = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS when the component mounts

    // document.querySelectorAll("img").forEach((img) => {
    //   img.addEventListener("load", () => {
    //     AOS.refresh();
    //   });
    // });
  }, []);
  return (
    <div className="lusia-conatiner-offer">
      <div className="lusia-container-full-contained-offer">
        <div className="lusia-left-offer">
          <div
            className="lusia-contained-offer"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-duration="600"
          >
            <div className="expert-content10">
              <div className="expert-img10">
                <img src={Line} alt="" />
                <div className="expert-heading-control-offer">
                  What is Invisalign?
                </div>
              </div>
            </div>
            <div className="lusia-para-offer">
              <p>
                They offer a flexible and convenient solution for those who want
                to straighten their teeth while maintaining a natural look.
              </p>
            </div>
            <div className="about-button">
              <Link>
                <button>
                  Get In Touch
                  <img src={arrow} alt="" />
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="lusia-right-offer"
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-duration="700"
        >
          <div className="lusia-right-contained-offer">
            <div className="right-para-offer">
              <p>
                Invisalign is an innovative orthodontic system that uses
                custom-made, clear plastic aligners to gradually straighten
                teeth. It's an ideal solution for people looking to improve
                their smile without the appearance of traditional metal braces.
              </p>
            </div>
            <div className="right-para-offer">
              <p>
                Invisalign aligners are virtually invisible, comfortable to
                wear, and removable for eating, brushing, and flossing making
                them less intrusive on your lifestyle.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
